import React, { useEffect, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import axios from 'axios';

export default function SetPasswordModal({ pass, setPass, userNameEmail }) {
    const user = JSON.parse(localStorage.getItem('user'))
    const inputRef = useRef(null);
    const s = [user.user_uid, user.tenant.tenants_guid]

    useEffect(() => {
        return () => {
            setPass('');
        };
    }, [])

    const onChangePass = (event) => {
        const value = event.target.value
        setPass(value)
    }

    return (
        <Box>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <div style={{ marginBottom: 32 }}>
                    <Typography varinant="h6" style={{ marginRight: 24 }}>Username</Typography>
                    <TextField
                        value={userNameEmail.user_name}
                        label="username"
                        disabled={true}
                        type="text"
                    />
                </div>
                <div>
                    <Typography varinant="h6" style={{ marginRight: 24 }}>Password</Typography>
                        <TextField
                            inputRef={inputRef}
                            onClick={() => {
                                inputRef.current.focus()
                            }}
                            onChange={onChangePass}
                            value={pass}
                            type="password"
                            label="Password"
                        />
                </div>
            </div>
        </Box>
    );
};