import gql from "graphql-tag";

//-------------------------MAPS

export const addMaps = gql `
    mutation addMaps($maps_guid: uuid, $maps_name: String, $maps_types_guid: uuid, $maps_real_size: String, $maps_img: String, $data_sources_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "add_maps",
            tasks_types_guid: "8b963a5b-7dda-4795-a483-83faac55b44c",
            task_params:
            [
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_maps_name", param_type: "character varying", param_value: $maps_name },
                { param: "v_maps_types_guid", param_type: "uuid", param_value: $maps_types_guid },
                { param: "v_maps_real_size", param_type: "character varying", param_value: $maps_real_size },
                { param: "v_maps_img", param_type: "text", param_value: $maps_img },
                { param: "v_data_sources_guid", param_type: "uuid", param_value: $data_sources_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ],
        }) {
                affected_rows
                returning {
                    tasks_guid
                  }
            }
}`;

export const updateMaps = gql `
    mutation update_maps($maps_guid: uuid, $maps_name: String, $maps_img: String, $maps_types_guid: uuid, $maps_real_size: String, $data_sources_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "update_maps",
            tasks_types_guid: "6d70e35d-8d06-4d05-8952-5656abc56147",
            task_params:
            [
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_maps_name", param_type: "character varying", param_value: $maps_name },
                { param: "v_maps_types_guid", param_type: "uuid", param_value: $maps_types_guid },
                { param: "v_maps_real_size", param_type: "character varying", param_value: $maps_real_size },
                { param: "v_maps_img", param_type: "character varying", param_value: $maps_img },
                { param: "v_data_sources_guid", param_type: "uuid", param_value: $data_sources_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const activateMaps = gql `
    mutation activate_maps($maps_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "activate_maps",
            tasks_types_guid: "6f971873-d2cd-4768-bd7e-3f5e4ae02624",
            task_params:
            [
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const deactivateMaps = gql `
    mutation deactivate_maps($maps_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "deactivate_maps",
            tasks_types_guid: "fdd69060-39b2-4315-9339-56c78ddf7ea3",
            task_params:
            [
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const deleteMaps = gql `
    mutation del_maps($maps_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "del_maps",
            tasks_types_guid: "fdcd3f46-8e1e-4e60-9125-4ec4edda54a6",
            task_params:
            [
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ],
        }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;


//-------------------------Geometries Group todo

export const addGeometriesGroup = gql `
    mutation add_geometries_group($geometries_groups_default_display_name: String, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "add_geometries_group",
            tasks_types_guid: "9e27d310-abd0-47ce-a61d-b8d911796561",
            task_params:
            [
                { param: "v_geometries_groups_default_display_name", param_type: "character varying", param_value: $geometries_groups_default_display_name },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ]
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const updateGeometriesGroup = gql `
    mutation update_geometries_group($geometries_groups_guid: uuid, $geometries_groups_default_display_name: String, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "update_geometries_group",
            tasks_types_guid: "34024615-8e5d-43ad-aeee-c7ce80aed21c",
            task_params:
            [
                { param: "v_geometries_groups_guid", param_type: "character varying", param_value: $geometries_groups_guid },
                { param: "v_geometries_groups_default_display_name", param_type: "character varying", param_value: $geometries_groups_default_display_name },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const activateGeometriesGroup = gql `
    mutation activate_geometries_group($geometries_groups_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "activate_geometries_group",
            tasks_types_guid: "436e6319-fe3c-4850-8e2e-b965d7ba3d2b",
            task_params:
            [
                { param: "v_geometries_groups_guid", param_type: "character varying", param_value: $geometries_groups_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const deactivateGeometriesGroup = gql `
    mutation deactivate_geometries_group($geometries_groups_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "deactivate_geometries_group",
            tasks_types_guid: "6f3ebaf3-8255-4f88-b217-be9fc70ad002",
            task_params:
            [
                { param: "v_geometries_groups_guid", param_type: "character varying", param_value: $geometries_groups_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const delGeometriesGroup = gql `
    mutation del_geometries_group($geometries_groups_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "del_geometries_group",
            tasks_types_guid: "ae22e09a-48a9-43d9-831a-e8cfcc4c96a7",
            task_params:
            [
                { param: "v_geometries_groups_guid", param_type: "character varying", param_value: $geometries_groups_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale }
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

//-------------------------map view (for map center and zoom settings) this func calls after map creation

export const addMapsView = gql `
    mutation add_maps_views($maps_views_guid: uuid, $maps_views_name: String, $maps_views_centroid: Object, $maps_views_zoom: Number, $maps_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "add_maps_views",
            tasks_types_guid: "54055835-ec05-46dd-bcd6-fb0d50c52610",
            task_params:
            [
                { param: "v_maps_views_guid", param_type: "uuid", param_value: $maps_views_guid },
                { param: "v_maps_views_name", param_type: "character varying", param_value: $maps_views_name },
                { param: "v_maps_views_centroid", param_type: "geometry", param_value: $maps_views_centroid },
                { param: "v_maps_views_zoom", param_type: "integer", param_value: $maps_views_zoom },
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

//-------------------------map view (for map center and zoom settings) this func calls after map saving (allocate regions to map)

export const updateMapsView = gql `
    mutation update_maps_views($maps_views_guid: uuid, $maps_views_name: String, $maps_views_centroid: Object, $maps_views_zoom: Number, $maps_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "update_maps_views",
            tasks_types_guid: "512143f6-b0a1-4feb-bee3-017e88220ab8",
            task_params:
            [
                { param: "v_maps_views_guid", param_type: "uuid", param_value: $maps_views_guid },
                { param: "v_maps_views_name", param_type: "character varying", param_value: $maps_views_name },
                { param: "v_maps_views_centroid", param_type: "geometry", param_value: $maps_views_centroid },
                { param: "v_maps_views_zoom", param_type: "integer", param_value: $maps_views_zoom },
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const activateMapsView = gql `
    mutation activate_maps_views($maps_views_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "activate_maps_views",
            tasks_types_guid: "cf7c22ef-bab2-4efd-9f61-f9df1af10d05",
            task_params:
            [
                { param: "v_maps_views_guid", param_type: "uuid", param_value: $maps_views_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;


export const deactivateMapsView = gql `
    mutation deactivate_maps_views($maps_views_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "deactivate_maps_views",
            tasks_types_guid: "853a1318-e816-4b7a-bd72-f25a46a66a36",
            task_params:
            [
                { param: "v_maps_views_guid", param_type: "uuid", param_value: $maps_views_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const delMapsView = gql `
    mutation del_maps_views($maps_views_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "del_maps_views",
            tasks_types_guid: "c371ba66-3d0c-4536-ae19-6859908a5361",
            task_params:
            [
                { param: "v_maps_views_guid", param_type: "uuid", param_value: $maps_views_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;


///////////////////// regions

export const addRegions = gql `
    mutation add_regions($regions_guid: uuid, $regions_name: String, $regions_types_guid: uuid, $regions_definition: Object, $regions_centroid: Object, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "add_regions",
            tasks_types_guid: "6d89de7e-fb76-4e8a-8ae9-ef3aa20c7b18",
            task_params:
            [
                { param: "v_regions_guid", param_type: "uuid", param_value: $regions_guid },
                { param: "v_regions_name", param_type: "character varying", param_value: $regions_name },
                { param: "v_regions_types_guid", param_type: "uuid", param_value: $regions_types_guid },
                { param: "v_regions_definition", param_type: "geometry", param_value: $regions_definition },
                { param: "v_regions_centroid", param_type: "geometry", param_value: $regions_centroid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const updateRegions = gql `
    mutation update_regions($regions_guid: uuid, $regions_name: String, $regions_types_guid: uuid, $regions_definition: Object, $regions_centroid: Object, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "update_regions",
            tasks_types_guid: "cc702dd0-d320-4d55-a027-d3c34961a880",
            task_params:
            [
                { param: "v_regions_guid", param_type: "uuid", param_value: $regions_guid },
                { param: "v_regions_name", param_type: "character varying", param_value: $regions_name },
                { param: "v_regions_types_guid", param_type: "uuid", param_value: $regions_types_guid },
                { param: "v_regions_definition", param_type: "geometry", param_value: $regions_definition },
                { param: "v_regions_centroid", param_type: "geometry", param_value: $regions_centroid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const deleteRegions = gql `
    mutation del_regions($regions_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "del_regions",
            tasks_types_guid: "7e9b484f-67db-4d6a-8c8f-be8dcbd1c9ff",
            task_params:
            [
                { param: "v_regions_guid", param_type: "uuid", param_value: $regions_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const addRegionsGroups = gql `
    mutation add_regions_groups($regions_groups_guid: uuid, $regions_groups_name: String, $maps_types_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "add_regions_groups",
            tasks_types_guid: "1194bc8a-b81e-4980-a035-3b2e4fe79a3c",
            task_params:
            [
                { param: "v_regions_groups_guid", param_type: "uuid", param_value: $regions_groups_guid },
                { param: "v_regions_groups_name", param_type: "character varying", param_value: $regions_groups_name },
                { param: "v_maps_types_guid", param_type: "uuid", param_value: $maps_types_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const deleteRegionsGroup = gql `
    mutation del_regions_groups($regions_groups_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "del_regions_groups",
            tasks_types_guid: "10410e45-c2db-40cf-afe7-4808ecaada3b",
            task_params:
            [
                { param: "v_regions_groups_guid", param_type: "uuid", param_value: $regions_groups_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

// привязать регион к группе
export const addRegionsGroupsRegions = gql `
    mutation add_regions_groups_regions($regions_groups_regions_guid: uuid, $regions_groups_guid: uuid, $regions_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "add_regions_groups_regions",
            tasks_types_guid: "2bff7870-a6a6-4ab6-8f23-355e7eef6fcb",
            task_params:
            [
                { param: "v_regions_groups_regions_guid", param_type: "uuid", param_value: $regions_groups_regions_guid },
                { param: "v_regions_groups_guid", param_type: "uuid", param_value: $regions_groups_guid },
                { param: "v_regions_guid", param_type: "uuid", param_value: $regions_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

// export const deleteRegionsGroupsRegions = gql `
//     mutation del_regions_groups_regions($regions_groups_regions_guid: uuid, $locale: String){
//         insert_tasks_tasks(objects: {
//             task_name: "del_regions_groups_regions",
//             tasks_types_guid: "18c05fed-6145-49fa-84cb-3e1490a317fe",
//             task_params:
//             [
//                 { param: "v_regions_groups_regions_guid", param_type: "uuid", param_value: $regions_groups_regions_guid },
//                 { param: "v_locale", param_type: "character varying", param_value: $locale },
//             ],
//             }) {
//                 affected_rows
//                 returning {
//                     tasks_guid
//                   }
//           }
// }`;

// отвязать регион от группы
export const deleteRegionsGroupsRegions = gql `
    mutation del_regions_groups_regions2($regions_groups_guid: uuid, $regions_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "del_regions_groups_regions",
            tasks_types_guid: "ab375ce6-bbed-449c-9d86-9e4234e6d940",
            task_params:
            [
                { param: "v_regions_groups_guid", param_type: "uuid", param_value: $regions_groups_guid },
                { param: "v_regions_guid", param_type: "uuid", param_value: $regions_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const addMapsRegionsGroups = gql `
    mutation add_maps_regions_groups($maps_regions_groups_guid: uuid, $maps_guid: uuid, $regions_groups_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "add_maps_regions_groups",
            tasks_types_guid: "47e8e4b7-e981-42b9-8cfb-40412fa287ea",
            task_params:
            [
                { param: "v_maps_regions_groups_guid", param_type: "uuid", param_value: $maps_regions_groups_guid },
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_regions_groups_guid", param_type: "uuid", param_value: $regions_groups_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;


export const deleteMapsRegionsGroups = gql `
    mutation del_maps_regions_groups($maps_regions_groups_guid: uuid, $maps_guid: uuid, $regions_groups_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "del_maps_regions_groups",
            tasks_types_guid: "923553e8-4571-4e54-97a5-1c87704f147c",
            task_params:
            [
                { param: "v_maps_regions_groups_guid", param_type: "uuid", param_value: $maps_regions_groups_guid },
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_regions_groups_guid", param_type: "uuid", param_value: $regions_groups_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

// all regions will be deleted from map if v_regions_groups_guid is null
export const deleteMapsRegionsGroupsByMapAndGroup = gql `
    mutation del_maps_regions_groups2($maps_guid: uuid, $regions_groups_guid: uuid, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "del_maps_regions_groups2",
            tasks_types_guid: "e4c6794e-f3e4-469d-b800-0df795859303",
            task_params:
            [
                { param: "v_maps_guid", param_type: "uuid", param_value: $maps_guid },
                { param: "v_regions_groups_guid", param_type: "uuid", param_value: $regions_groups_guid },
                { param: "v_locale", param_type: "character varying", param_value: $locale },
            ],
            }) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;
