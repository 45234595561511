import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { useEffect } from "react";
import { FileManagerComponent, Inject, NavigationPane, DetailsView, Toolbar } from '@syncfusion/ej2-react-filemanager';

const Overview = () => {
    const hostUrl = "http://localhost:3000/api";  // Ensure this is your backend server URL

    return (
        <div>
            <div className="control-section">
                <FileManagerComponent
                    id="overview_file"
                    ajaxSettings={{
                        url: hostUrl + "/filemanager/read",      // API for listing files and folders
                        uploadUrl: hostUrl + '/filemanager/upload',  // API for uploading files
                        downloadUrl: hostUrl + '/filemanager/download', // API for downloading files
                        deleteUrl: hostUrl + '/filemanager/delete',  // API for deleting files/folders
                        createUrl: hostUrl + '/filemanager/create',  // API for creating folders
                        renameUrl: hostUrl + '/filemanager/rename'   // API for renaming files/folders
                    }}
                    toolbarSettings={{
                        items: ['NewFolder', 'SortBy', 'Cut', 'Copy', 'Paste', 'Delete', 'Refresh', 'Download', 'Rename', 'Selection', 'View', 'Details']
                    }}
                    contextMenuSettings={{
                        file: ['Cut', 'Copy', '|', 'Delete', 'Download', 'Rename', '|', 'Details'],
                        layout: ['SortBy', 'View', 'Refresh', '|', 'Paste', '|', 'NewFolder', '|', 'Details', '|', 'SelectAll'],
                        visible: true
                    }}
                    view={"Details"}
                    onError={(error) => console.error("Error in FileManager:", error)}
                >
                    <Inject services={[NavigationPane, DetailsView, Toolbar]} />
                </FileManagerComponent>
            </div>
        </div>
    );
}

export default Overview;
