import React, { useRef } from 'react';
import {Form} from '@patternfly/react-core';
import {TextBoxComponent} from "@syncfusion/ej2-react-inputs";
import { useDebouncedCallback } from 'use-debounce';

const CreateRegionsGroupForm = (props) => {
    const ref = useRef(null)
    const inputRef = useRef(null)
    const updateName = (value) => props.setFormData((prev) => ({...prev, regions_groups_name: value}))

    const debounced = useDebouncedCallback(
        (value) => {
            updateName(value);
        },
        400
    );

    return (
        <Form style={{width: "100%", padding: "10px 15px"}}>
            <section className='dynamic-forms'>
                <label style={{fontSize: '12px', color: '#757575'}}>Region Group Name</label>
                <div ref={ref}>
                    <input
                        className="input-manage-regions"
                        ref={inputRef}
                        type="text"
                        onChange={(event) => {
                            debounced(event.target.value)
                        }}
                        onClick={() => {
                            inputRef.current?.focus()
                        }}/>
                </div>
            </section>
        </Form>
    );

}

export default CreateRegionsGroupForm;