import React, { useState, useEffect, useRef, createContext, useMemo, useContext } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import GridPanel from "../gridPanel"
import ReportPanel from '../reportPanel';
import FormsPanel from '../formsPanel';
import PivotsPanel from '../pivotsPanel';
import MapPanel from '../Maps/mapPanel';
import CardsPanel from '../cardsPanel';
import CardsLayoutPanel from '../cardsLayoutPanel';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';


const NewPanelsContent = ({ panel, panelJSON, screenList, d, dynamicGridHeight }) => {
    const client = useApolloClient();
    const default_master_guid = useSelector(state => state.mainReducer.default_master_guid);

    const isValidDate = (replacement) => {
        // Try to parse the string with Moment
        const parsedDate = moment(replacement, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ [(]Z[)]', true);

        // Check if the parsing was successful and the date is valid
        return parsedDate.isValid();
    };



    const card = () => {

        switch (panelJSON && panelJSON.sys_panels_types_guid) {
            //grid
            case 'ed9d4d8c-f911-487b-8f0f-49356ec67953':
                // if (d) {
                //     let screenParameters = panelJSON.query_parameters
                //     var gridQuery = panelJSON.query
                //     let rowCountQuery = panelJSON.row_count
                //     for (let i of screenParameters) {
                //         let replacement = d && d[i]
                //         if (replacement === null || replacement.length < 1) {
                //             gridQuery = "{}"
                //             rowCountQuery = "{}"
                //             break;
                //         }
                //         else {
                //             if (isValidDate(replacement)) {
                //                 replacement = moment(replacement, 'yyyy-MM-dd', true).format()
                //                 replacement = replacement.slice(0, -6)
                //             }
                //             replacement = '\"' + replacement + '\"'

                //         }


                //         gridQuery = gridQuery.replace(`@${i}`, replacement)
                //         rowCountQuery = rowCountQuery.replace(`@${i}`, replacement)
                //     }
                //     gridQuery = gridQuery.substring(1, gridQuery.length - 1)
                //     console.log(gridQuery)
                //     rowCountQuery = rowCountQuery.substring(1, rowCountQuery.length - 1)
                //     return <GridPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} gridQuery={gridQuery} dynamicGridHeight={dynamicGridHeight} rowCountQuery={rowCountQuery} />
                // }
                if (d) {
                    let screenParameters = panelJSON.query_parameters
                    var gridQuery = panelJSON.query
                    let rowCountQuery = panelJSON.row_count
                    for (let i of screenParameters) {
                        let replacement = d && d[i]
                        if (typeof replacement === 'string') {
                            replacement = '\"' + replacement + '\"'
                        }
                        else if(replacement !== null || replacement?.length > 1) {
                            if (isValidDate(replacement)) {
                                replacement = moment(replacement, 'yyyy-MM-dd', true).format()
                                replacement = replacement.slice(0, -6)
                            }
                            replacement = '\"' + replacement + '\"'
                        }

                        gridQuery = gridQuery.replace(`@${i}`, replacement)
                        rowCountQuery = rowCountQuery.replace(`@${i}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    gridQuery = gridQuery.substring(1, gridQuery.length - 1)
                    return <GridPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} gridQuery={gridQuery} queryName={queryName} dynamicGridHeight={dynamicGridHeight} rowCountQuery={rowCountQuery} />
                }
                else {
                    var gridQuery = panelJSON.query
                    let rowCountQuery = panelJSON.row_count
                    let replacement;
                    for (let i of screenList) {
                        if (i.required && i.param_value === null) {
                            gridQuery = "{}"
                            rowCountQuery = "{}"
                            break;
                        }
                        if (i.param_value !== null) {
                            replacement = '\"' + i.param_value + '\"'
                        }
                        else {
                            replacement = i.param_value
                        }
                        gridQuery = gridQuery.replace(`@${i.param}`, replacement)
                        rowCountQuery = rowCountQuery.replace(`@${i.param}`, replacement)
                    }
                    gridQuery = gridQuery.substring(1, gridQuery.length - 1)
                    rowCountQuery = rowCountQuery.substring(1, rowCountQuery.length - 1)
                    return <GridPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} gridQuery={gridQuery} dynamicGridHeight={dynamicGridHeight} rowCountQuery={rowCountQuery} />
                }
            //report
            case 'e5f1e3aa-39fe-4ed6-b8f7-3a7724709671':
                let reportQuery = panelJSON.query.replace('@v_locale', 'en')
                reportQuery = reportQuery.substring(1, reportQuery.length - 1)
                return <ReportPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} reportQuery={reportQuery} />
            //form
            case '6d6cc369-ca19-4fe2-ac19-782b282dedb9':
                if (d) {
                    let screenParameters = panelJSON.query_parameters
                    var formsQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = d && d[i]
                        if (typeof replacement === 'string') {

                            replacement = '\"' + replacement + '\"'
                        }

                        formsQuery = formsQuery.replace(`@${i}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    formsQuery = formsQuery.substring(1, formsQuery.length - 1)
                    return <FormsPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} formsQuery={formsQuery} queryName={queryName} />
                }
                else {
                    var formsQuery = panelJSON.query
                    let animalsId;
                    let objectWithGuid;
                    let replacement;
                    for (let i of screenList) {
                        if (i.param.includes("guid") && panel.sys_actions_flags_guid !== "f56e5198-8c16-4668-90e9-cf1b03a205a9") {
                            if (i.panel_parameters_mapping[0].sys_columns_guid !== null) {
                                let copyOfI = JSON.parse(JSON.stringify(i))
                                const defaultMasterGuidObject = default_master_guid && Array.isArray(default_master_guid) ? default_master_guid.find(item => item.param === copyOfI.param) : null;
                                copyOfI.param_value = defaultMasterGuidObject?.param_value
                                animalsId = copyOfI
                            }
                            else {
                                objectWithGuid = i
                            }

                        }
                        if (i.param_value !== null) {
                            replacement = '\"' + i.param_value + '\"'
                        }
                        else {

                            replacement = i.param_value
                        }
                        formsQuery = formsQuery.replace(`@${i.param}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    formsQuery = formsQuery.substring(1, formsQuery.length - 1)
                    return <FormsPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} animalsId={animalsId} objectWithGuid={objectWithGuid} formsQuery={formsQuery} queryName={queryName} />
                }
            //pivot
            case "2bd806f3-bb05-415f-9233-b50b3f78c921":
                if (d) {
                    let screenParameters = panelJSON.query_parameters
                    var pivotsQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = d && d[i]
                        if (typeof replacement === 'string') {

                            replacement = '\"' + replacement + '\"'
                        }

                        pivotsQuery = pivotsQuery.replace(`@${i}`, replacement)
                    }
                    pivotsQuery = pivotsQuery.substring(1, pivotsQuery.length - 1)
                    return <PivotsPanel idx={panel.sys_panels_guid} panelJSON={panelJSON} panel={panel} pivotsQuery={pivotsQuery} dynamicGridHeight={dynamicGridHeight} />
                }
                else {
                    let pivotsQuery = panelJSON.query
                    let replacement;
                    for (let i of screenList) {
                        if (i.required && i.param_value === null) {
                            pivotsQuery = "{}"
                            break;
                        }
                        if (i.param_value !== null) {
                            replacement = '\"' + i.param_value + '\"'
                        }
                        else {
                            replacement = i.param_value
                        }
                        pivotsQuery = pivotsQuery.replace(`@${i.param}`, replacement)
                    }
                    pivotsQuery = pivotsQuery.substring(1, pivotsQuery.length - 1)
                    return <PivotsPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} pivotsQuery={pivotsQuery} dynamicGridHeight={dynamicGridHeight} />
                }
            //map
            case "3148cdd4-3540-46eb-8e9b-34ca223bbae7": {
                let mapsQuery = panelJSON.query
                if (screenList) {
                    for (let i of screenList) {
                        let replacement = '\"' + i.param_value + '\"'
                        mapsQuery = mapsQuery.replace(`@${i.param}`, replacement)
                    }
                }
                else {
                    mapsQuery = mapsQuery.replace('@v_locale', '"en"')
                }
                let queryName = panelJSON.query_name
                mapsQuery = mapsQuery.substring(1, mapsQuery.length - 1)
                return <MapPanel mapType={panel.isOpenStreet} panel={panel} panelJSON={panelJSON} mapsQuery={mapsQuery} queryName={queryName} />
            }
            // cards
            case "8c9f53c8-6db1-4ff8-8808-0ea4d7ff739f":
                if (d) {
                    let screenParameters = panelJSON.query_parameters
                    let cardsQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = d && d[i]
                        if (typeof replacement === 'string') {

                            replacement = '\"' + replacement + '\"'
                        }

                        cardsQuery = cardsQuery.replace(`@${i}`, replacement)
                    }
                    let queryName = panelJSON.query_name
                    cardsQuery = cardsQuery.substring(1, cardsQuery.length - 1)
                    return <CardsPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} cardsQuery={cardsQuery} queryName={queryName} />
                }
                else {
                    let cardsQuery = panelJSON.query
                    for (let i of screenList) {
                        let replacement = '\"' + i.param_value + '\"'
                        cardsQuery = cardsQuery.replace(`@${i.param}`, replacement)
                    }
                    cardsQuery = cardsQuery.substring(1, cardsQuery.length - 1)
                    return <CardsPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} cardsQuery={cardsQuery} queryName={queryName} />
                }

            case "cae6e007-1721-4f7d-8cff-eb9eff7cff93":
                if (d) {
                    let screenParameters = panelJSON.query_parameters
                    let cardsLayoutQuery = panelJSON.query
                    for (let i of screenParameters) {
                        let replacement = d && d[i]
                        if (typeof replacement === 'string') {

                            replacement = '\"' + replacement + '\"'
                        }

                        cardsLayoutQuery = cardsLayoutQuery.replace(`@${i}`, replacement)
                    }
                    cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1)
                    return <CardsLayoutPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} cardsLayoutQuery={cardsLayoutQuery} />
                }
                else {
                    let cardsLayoutQuery = panelJSON.query
                    for (let i of screenList) {
                        let replacement = '\"' + i.param_value + '\"'
                        cardsLayoutQuery = cardsLayoutQuery.replace(`@${i.param}`, replacement)
                    }
                    cardsLayoutQuery = cardsLayoutQuery.substring(1, cardsLayoutQuery.length - 1)
                    return <CardsLayoutPanel idx={panel.sys_panels_guid} panel={panel} panelJSON={panelJSON} cardsLayoutQuery={cardsLayoutQuery} />
                }

            default:
                return null
        }
    }


    return (
        <>
            {card()}
        </>
    );
}

export default NewPanelsContent