export const EDIT_MODE = 'EDIT_MODE';
export const EDIT_SHOW_POINT_MODE = 'EDIT_SHOW_POINT_MODE';
export const EDIT_SENSOR_TYPE_VISIBILITY = 'EDIT_SENSOR_TYPE_VISIBILITY';
export const EDIT_REGIONS_PICKER_VISIBILITY = 'EDIT_REGIONS_PICKER_VISIBILITY';
export const EDIT_MODE_STATIC_MAP = 'EDIT_MODE_STATIC_MAP';
export const EDIT_SHOW_POINT_MODE_STATIC_MAP = 'EDIT_SHOW_POINT_MODE_STATIC_MAP';
export const EDIT_SENSOR_TYPE_VISIBILITY_STATIC_MAP = 'EDIT_SENSOR_TYPE_VISIBILITY_STATIC_MAP';
export const EDIT_REGIONS_PICKER_VISIBILITY_STATIC_MAP = 'EDIT_REGIONS_PICKER_VISIBILITY_STATIC_MAP';

export const SET_CURRENT_MAP_TYPE = 'SET_CURRENT_MAP_TYPE';
export const SET_CURRENT_MAP = 'SET_CURRENT_MAP';

export const ADD_POLYGON = 'ADD_POLYGON';
export const EDIT_POLYGON = 'EDIT_POLYGON';
export const DELETE_POLYGON = 'DELETE_POLYGON';
export const SET_CURRENT_POLYGON = 'SET_CURRENT_POLYGON';

export const SET_DATA_SOURCES = 'SET_DATA_SOURCES';

export const SET_MAP_TYPES = 'SET_MAP_TYPES';
export const SET_MAPS = 'SET_MAPS';
export const ADD_MAP = 'ADD_MAP';
export const EDIT_MAP = 'EDIT_MAP';
export const DELETE_MAP = 'DELETE_MAP';

export const SET_REGIONS = 'SET_REGIONS';

export const SET_REGIONS_GROUPS = 'SET_REGIONS_GROUPS';
export const SET_CURRENT_REGIONS_GROUP = 'SET_CURRENT_REGIONS_GROUP';
export const SET_REGIONS_TYPES = 'SET_REGIONS_TYPES';
export const SET_REGIONS_TYPES_SETTINGS = 'SET_REGIONS_TYPES_SETTINGS';

export const SET_REGIONS_TYPES_STATIC = 'SET_REGIONS_TYPES_STATIC';

export const SET_ALLOCATED_REGIONS_GROUPS = 'SET_ALLOCATED_REGIONS_GROUPS';
export const SET_UNALLOCATED_REGIONS_GROUPS = 'SET_UNALLOCATED_REGIONS_GROUPS';

export const SET_UNSAVED_REGIONS_LENGTH = 'SET_UNSAVED_REGIONS_LENGTH';

export const SET_MAPS_VIEWS = 'SET_MAPS_VIEWS';
export const SET_CURRENT_MAPS_VIEW = 'SET_CURRENT_MAPS_VIEW';
export const ADD_MAPS_VIEW = 'ADD_MAPS_VIEW';
export const EDIT_MAPS_VIEW = 'EDIT_MAPS_VIEW';
export const DELETE_MAPS_VIEW = 'DELETE_MAPS_VIEW';
export const SET_PANEL_JSON = 'SET_PANEL_JSON';
export const SET_PANELS_VIEWS_GUID = 'SET_PANELS_VIEWS_GUID';
export const SET_PANELS_GUID = 'SET_PANELS_GUID';
export const SET_SCREEN_GUID = 'SET_SCREEN_GUID';
export const TOGGLE_REFRESH_BUTTON = 'TOGGLE_REFRESH_BUTTON';
export const SAVE_REFRESHED_PANELS_GUID = 'SAVE_REFRESHED_PANELS_GUID';
export const SAVE_REQUIRED_STATUS_SCREEN = 'SAVE_REQUIRED_STATUS_SCREEN';
export const SAVE_SCREEN_REFRESH_TIME = 'SAVE_SCREEN_REFRESH_TIME';
export const DEFAULT_MASTER_GUID = 'DEFAULT_MASTER_GUID';
export const ARGS_ITEM = 'ARGS_ITEM';
