import React, { useState, useEffect, useContext } from 'react'
import Popover from '@mui/material/Popover';
import { IconButton, List, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useApolloClient, useSubscription, useQuery } from '@apollo/react-hooks';

import { GET_ALERTS } from '../../apollo/queries'
import { GET_ALERT_SUBSCRIPTION } from '../../apollo/subscriptions'
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from 'apollo-link-ws';
import { AlertItem } from './components/Alert/AlertItem'
import { GlobalTheme } from '../../context/GlobalTheme';

export const Alerts = ({ alerts = [], setAlerts }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  // const { data, loading } = useQuery(GET_ALERTS)
  const client = useApolloClient();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  useEffect(() => {
    if (open) {
      const getAlerts = async () => {
        const { data } = await client.query({ query: GET_ALERTS })
        setAlerts(data.alerts)
      }
      getAlerts()
    }
  }, [open])

  const globalTheme = useContext(GlobalTheme)

  return (
    <>
      <IconButton onClick={handleClick} color="inherit" >
        <Badge badgeContent={alerts.length} color="secondary">
          <NotificationsIcon style={{ fill: globalTheme[globalTheme.activeTheme]?.svgFill }} />
        </Badge>
      </IconButton>
      {alerts?.length !== 0 && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {alerts.map((item, idx) => !item?.alert_body ? null : (
              <List
                key={idx}
                sx={{ width: '100%', minWidth: 340, bgcolor: 'background.paper' }}
              >
                <AlertItem
                  alertBody={item.alert_body}
                  alertLevel={item.alert_level}
                  alertTitle={item.alert_title}
                  alertValidFrom={item.alert_valid_from}
                  channel={item.channel}
                />
              </List>
            ))}
        </Popover>
      )}
    </>
  )
}
