import * as actionTypes from './treeViewActions';

export const initialState = {
    anchorEl: null,
    isMapDialogOpen: false,
    isDrawPolygon: false,
    drawnPolygonLatLngs: null,
    drawnPolygonName: '',
    isTreeViewSaveButtonClicked: false,
    isTreeViewToggled: false,
    regionsGroupsGuidValue: null,
};

const treeViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TOGGLE_TREEVIEW_POPOVER:
            return { ...state, anchorEl: action.payload };
        case actionTypes.TOGGLE_MAP_DIALOG:
            return { ...state, isMapDialogOpen: action.payload };
        case actionTypes.POLYGON_DRAW_PROCESS:
            return { ...state, isDrawPolygon: action.payload };
        case actionTypes.SAVE_POLYGON_LAT_LNGS:
            return { ...state, drawnPolygonLatLngs: action.payload };
        case actionTypes.CHANGE_POLYGON_NAME:
            return { ...state, drawnPolygonName: action.payload };
        case actionTypes.CLICK_TREE_VIEW_DIALOG_SAVE_BUTTON:
            return { ...state, isTreeViewSaveButtonClicked: action.payload };
        case actionTypes.TOGGLE_TREE_VIEW_SAVE:
            return { ...state, isTreeViewToggled: action.payload };
        case actionTypes.GET_REGIONS_GROUPS_GUID_VALUE:
            return { ...state, regionsGroupsGuidValue: action.payload };

        default:
            return state;
    }
};

export default treeViewReducer;
