import styled from 'styled-components';
import {Typography as TypographyMui} from '@mui/material'
import ListItemText from '@mui/material/ListItemText';
import ListItemMui from '@mui/material/ListItem';

export const ListItemTextSecondary = styled(ListItemText)`
  margin-left: 8px;
`

export const PrimaryElement = styled('div')`
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
`

export const ListItem = styled(ListItemMui)`
  border-bottom: 2px solid #bbd2ec;
`

export const TooltipElement = styled('div')`
  font-size: 12px;
`
export const SecondaryTitle = styled('div')`
  width: 200px;
`