const showToast = (toastRef, message, type) => {
    toastRef.current.show({
      content: message,
      cssClass: `e-toast-${type} custom-toast-position`,
      position: { X: 'Right', YOffset: '1000' },
      animation: { show: { duration: 500 }, hide: { duration: 500 } },
      timeOut: 3000,
    });
  };
  
  export default showToast;