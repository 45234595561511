import styled from 'styled-components';

export const ModalContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > div {
    min-width: 300px;
    padding: 12px 16px;
    border-radius: 8px;
    background: #ffffff;
  }
`