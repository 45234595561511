import React, { useEffect, useState } from "react";
import { SynUserContextProvider } from "../../context/UserContext";
import SynRouter from "../../router/router";
import AuthService from "../../services/AuthService";
import { BrowserRouter } from "react-router-dom";

function Auth() {
    const [currentUser, setCurrentUser] = useState(undefined);

    useEffect(() => {
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
    }, []);

    const logOut = () => {
        AuthService.logout();
    };

    return (
        <SynUserContextProvider value={currentUser}>
            <BrowserRouter>
                <SynRouter />
            </BrowserRouter>
        </SynUserContextProvider>
    );
}

export default Auth;