import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_SYS_SCREENS_PANELS_JSON } from '../../../apollo/queries';
import PanelsContent from './panelsContent';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const ConditionalContent = ({ panel, sidebarScreenParameters, screenList, dynamicGridHeight }) => {
    const client = useApolloClient();
    const location = useLocation();
    const dispatch = useDispatch();
    const panelsViewGuid = useSelector((state) => state.mainReducer.panelsViewGuid);
    const panelsGuid = useSelector((state) => state.mainReducer.panelsGuid);
    const screenGuid = useSelector((state) => state.mainReducer.screenGuid);
    const [panelJSON, setPanelJSON] = useState(null);



    useEffect(() => {
        const _getScreensPanelsJSON = async () => {

            if (panelsViewGuid === null) {
                dispatch({ type: 'SET_SCREEN_GUID', data: location.pathname })
                const { data } = await client.query({
                    query: GET_SYS_SCREENS_PANELS_JSON,
                    variables: {
                        v_sys_screens_panels_guid: panel.sys_screens_panels_guid,
                        v_sys_panels_view_guid: null
                    }
                })
                setPanelJSON(data.dal_get_sys_screens_panels_json[0].gql_results)
                dispatch({ type: 'SET_PANEL_JSON', data: data.dal_get_sys_screens_panels_json[0].gql_results })
            }
            else if (panel.sys_panels_guid === panelsGuid) {
                const { data } = await client.query({
                    query: GET_SYS_SCREENS_PANELS_JSON,
                    variables: {
                        v_sys_screens_panels_guid: panel.sys_screens_panels_guid,
                        v_sys_panels_view_guid: panelsViewGuid || null
                    }
                })
                setPanelJSON(data.dal_get_sys_screens_panels_json[0].gql_results)
                dispatch({ type: 'SET_PANEL_JSON', data: data.dal_get_sys_screens_panels_json[0].gql_results })
            }

        }

        if (location.pathname !== screenGuid) {
            dispatch({ type: 'SET_PANELS_VIEWS_GUID', data: null })
        }

        _getScreensPanelsJSON()

        return () => {
            dispatch({ type: 'SET_PANELS_VIEWS_GUID', data: null })
            setPanelJSON(null)

        };

    }, [panel, panelsViewGuid]);

    return (
        <>
            <PanelsContent panel={panel} sidebarScreenParameters={sidebarScreenParameters} panelJSON={panelJSON} screenList={screenList} dynamicGridHeight={dynamicGridHeight} />
        </>
    )
}

export default ConditionalContent