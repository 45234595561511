import axios from "axios";

const API_URL = process.env.REACT_APP_LOGIN_ENDPOINT;

const AuthService = {
    login: (username, password) => {
        return axios
            .post(`${API_URL}login`, {
                username,
                password
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.data.access_token) {
                    localStorage.setItem("user", JSON.stringify(response.data));
                }
                return response.data;
            });
    },

    isAuthenticated: () => {
        const parseJwt = token => {
            try {
                const stringObj = atob(token.split(".")[1]);
                const obj = JSON.parse(stringObj)
                return obj.exp
            } catch (e) {
                return null;
            }
        };
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            const token = user.access_token;
            try {
                const exp = parseJwt(token);
                if (Date.now() >= exp * 1000) {
                    return false;
                }
            } catch (err) {
                return false;
            }
            return true;
        }
        return false;
    },

    logout: () => {
        localStorage.removeItem("user");
    },

    register: (username, email, password) => {
        return axios.post(`${API_URL}signup`, {
            username,
            email,
            password
        });
    },

    getCurrentUser: () => {
        return JSON.parse(localStorage.getItem('user'));
    }
};

export default AuthService;
