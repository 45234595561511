import React, { useRef, useState, useEffect, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useApolloClient } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { FolderOpen, CreateNewFolder, AddLocation, StopCircle, Save } from '@mui/icons-material';
import { Player } from '@lottiefiles/react-lottie-player';
import { GET_REGIONS_TYPES_LIST, GET_SYS_OWNERS_LIST, GET_REGIONS_EXPLORER_TYPES_LIST, GET_TYPES_EXPLORER_TYPES_LIST } from '../../../apollo/queries';

const TreeViewPopoverHeader = ({
    treeRef,
    treeDataObject,
    treeViewNodeState,
    setTreeData,
    manageRegionsPolygonsTreeViewUpdate,
    setOwnersValue
}) => {

    const client = useApolloClient();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [regionTypesList, setRegionTypesList] = useState([]);
    const [ownersList, setOwnersList] = useState([]);
    const [typeValue, setTypeValue] = useState('');
    const [typeText, setTypeText] = useState('');
    const [ownersValue, setOwnersValueState] = useState('');
    const [ownersText, setOwnersText] = useState('');

    const isDrawPolygon = useSelector(state => state.treeViewReducer.isDrawPolygon);
    const regionsGroupsGuidValue = useSelector(state => state.treeViewReducer.regionsGroupsGuidValue);

    const regionsTypesFields = { text: 'region_type_name', value: 'regions_types_guid' };
    const ownersFields = { text: 'name', value: 'sys_owners_guid' };
    const { nodeLevel, selectedNodeId, isSelectedNodeRegion, isSelectedNodeType } = treeViewNodeState;

    const onTypeChange = (args) => {
        setTypeValue(args.itemData === null ? 'null' : args.itemData[regionsTypesFields.value].toString());
        setTypeText(args.itemData === null ? 'null' : args.itemData[regionsTypesFields.text].toString());
    };

    const onOwnersChange = (args) => {
        setOwnersValue(args.itemData === null ? 'null' : args.itemData[ownersFields.value].toString());
        setOwnersValueState(args.itemData === null ? 'null' : args.itemData[ownersFields.value].toString());
        setOwnersText(args.itemData === null ? 'null' : args.itemData[ownersFields.text].toString());
    };

    const handleAddType = () => {
        if (isSelectedNodeRegion) {
            const newNode = {
                instance_guid: typeValue,
                instance_name: typeText,
                is_checked: null,
                regions_groups_regions_guid: uuidv4(),
                parent_regions_groups_regions_guid: treeDataObject["regions_groups_regions_guid"],
                type: "Type"
            }
            treeRef.current.addNodes([newNode], selectedNodeId);
            setTreeData(treeRef.current.treeData)
        }
    }

    function findObjectById(arr, instance_guid) {
        for (const obj of arr) {
            if (obj.regions_groups_regions_guid === instance_guid) {
                return obj;
            }
            if (obj.child) {
                const foundChild = findObjectById(obj.child, instance_guid);
                if (foundChild) {
                    return foundChild;
                }
            }
        }
        return null;
    }

    const findExistedObjectById = (arr, instance_guid) => {
        for (const obj of arr) {
            if (obj.instance_guid === instance_guid) {
                return obj;
            }
        }
        return null;
    }


    const handleAddChildNode = async () => {
        const treeViewData = treeRef.current.treeData;
        const foundObject = findObjectById(treeViewData, selectedNodeId);

        setIsLoading(true);
        if (foundObject.type === 'Region') {
            const { data } = await client.query({ query: GET_REGIONS_EXPLORER_TYPES_LIST, variables: { regions_groups_guid: regionsGroupsGuidValue } })
            data.dal_get_um_regions_explorer_types_list.forEach(row => {
                let isRowInTreeView = findExistedObjectById(treeViewData, row["instance_guid"]);
                if ((row["regions_groups_regions_guid"] != null && nodeLevel == row["row_level"]) && (row["parent_regions_groups_regions_guid"] === foundObject["regions_groups_regions_guid"] || row["parent_regions_groups_regions_guid"] === null)) {
                    if (!isRowInTreeView) {
                        const newNode = {
                            instance_guid: row["instance_guid"],
                            instance_name: row["number_of_children"] === null ? row["instance_name"] : `${row["instance_name"]} - (${row["number_of_children"]})`,
                            is_checked: row["is_checked"],
                            regions_groups_regions_guid: row["regions_groups_regions_guid"],
                            parent_regions_groups_regions_guid: row["parent_regions_groups_regions_guid"],
                            number_of_children: row["number_of_children"],
                            type: 'Type'
                        };
                        treeRef.current.addNodes([newNode], selectedNodeId);
                        setTreeData(treeRef.current.treeData)
                    }

                }
            })

        }
        else {
            const foundParentObject = findObjectById(treeViewData, foundObject.parent_id);
            const { data } = await client.query({
                query: GET_TYPES_EXPLORER_TYPES_LIST, variables: {
                    parentRegionType: foundParentObject.instance_guid,
                    childRegionType: foundObject.instance_guid,
                    regions_groups_guid: regionsGroupsGuidValue
                }
            });
            data.dal_get_um_regions_explorer_regions_list.forEach(row => {
                let isRowInTreeView = findExistedObjectById(treeViewData, row["instance_guid"]);
                if (!isRowInTreeView) {
                    const newNode = {
                        instance_guid: row["instance_guid"],
                        instance_name: row["number_of_children"] === null ? row["instance_name"] : `${row["instance_name"]} - (${row["number_of_children"]})`,
                        is_checked: row["is_checked"],
                        number_of_children: row["number_of_children"] === null ? row["instance_name"] : `${row["instance_name"]} - (${row["number_of_children"]})`,
                        regions_groups_regions_guid: row["regions_groups_regions_guid"] === null ? uuidv4() : row["regions_groups_regions_guid"],
                        parent_regions_groups_regions_guid: row["parent_regions_groups_regions_guid"] === null ? foundObject["regions_groups_regions_guid"] : row["parent_regions_groups_regions_guid"],
                        type: 'Region'
                    };

                    treeRef.current.addNodes([newNode], selectedNodeId);

                    setTreeData(treeRef.current.treeData)
                }

            })
        }
        setIsLoading(false);
    };

    const _getRegionsTypesList = async () => {
        const { data } = await client.query({ query: GET_REGIONS_TYPES_LIST })
        setRegionTypesList(data.dal_get_regions_types_list);
    }

    const _getSysOwnersList = async () => {
        const { data } = await client.query({ query: GET_SYS_OWNERS_LIST })
        setOwnersList(data.dal_get_sys_owners_list);
        const hasOwnersName = data.dal_get_sys_owners_list.find(item => item.name === 'User');
        if (hasOwnersName) {
            setOwnersValue(hasOwnersName.sys_owners_guid)
            setOwnersValueState(hasOwnersName.sys_owners_guid)
            setOwnersText(hasOwnersName.name)
        }
    }

    const _getFirstNodesOnRender = async (instanceGuidId) => {
        setIsLoading(true);
        const treeViewData = treeRef.current.treeData;
        const foundObject = findObjectById(treeViewData, instanceGuidId);
        if (foundObject.type === 'Region') {
            const { data } = await client.query({ query: GET_REGIONS_EXPLORER_TYPES_LIST })
            data.dal_get_um_regions_explorer_types_list.forEach(row => {
                if ((row["regions_groups_regions_guid"] != null && "1" == row["row_level"]) && (row["parent_regions_groups_regions_guid"] === foundObject["regions_groups_regions_guid"] || row["parent_regions_groups_regions_guid"] === null)) {
                    const newNode = {
                        instance_guid: row["instance_guid"],
                        instance_name: row["number_of_children"] === null ? row["instance_name"] : `${row["instance_name"]} - (${row["number_of_children"]})`,
                        is_checked: row["is_checked"],
                        regions_groups_regions_guid: row["regions_groups_regions_guid"],
                        parent_regions_groups_regions_guid: row["parent_regions_groups_regions_guid"],
                        number_of_children: row["number_of_children"],
                        type: 'Type'
                    };
                    treeRef.current.addNodes([newNode], instanceGuidId);
                    setTreeData(treeRef.current.treeData)

                }
            })
        }
        setIsLoading(false);
    }

    useEffect(() => {
        _getRegionsTypesList()
        _getSysOwnersList()
        _getFirstNodesOnRender('93f0d2ed-ada3-449d-a9c2-c22a38b389fb')
    }, [])

    return (

        <div className="d-flex justify-content-between align-items-stretch my-2">
            {isLoading ?
                < Player
                    autoplay
                    loop
                    src="https://assets7.lottiefiles.com/packages/lf20_2svadkl0.json"
                    style={{ width: '48px', height: '34px' }}
                /> :
                <ButtonComponent onClick={handleAddChildNode} disabled={selectedNodeId === null} title="Load a node">
                    <FolderOpen />
                </ButtonComponent>
            }
            <div className="d-flex justify-content-center px-1" style={{ maxWidth: '200px' }}>
                <ComboBoxComponent id="owner" dataSource={ownersList} fields={ownersFields} value={ownersValue} change={onOwnersChange} placeholder="Select owner" popupHeight="220px" />
            </div>
            <div className="d-flex justify-content-center px-1" style={{ maxWidth: '200px' }}>
                <ComboBoxComponent id="types" enabled={isSelectedNodeRegion} dataSource={regionTypesList} fields={regionsTypesFields} change={onTypeChange} value={typeValue} placeholder="Select type" popupHeight="220px" />
            </div>
            <ButtonComponent onClick={handleAddType} disabled={typeText === '' || isSelectedNodeType} title="Add type">
                <CreateNewFolder />
            </ButtonComponent>
            {
                !isDrawPolygon ?
                    <ButtonComponent onClick={() => dispatch({ type: 'POLYGON_DRAW_PROCESS', payload: true })} disabled={!isSelectedNodeType} title="Add polygon">
                        <AddLocation />
                    </ButtonComponent> :
                    <ButtonComponent onClick={() => dispatch({ type: 'POLYGON_DRAW_PROCESS', payload: false })} disabled={!isSelectedNodeType} title="Stop adding">
                        <StopCircle />
                    </ButtonComponent>
            }

            <ButtonComponent onClick={manageRegionsPolygonsTreeViewUpdate} style={{ float: "right" }} title="Save changes">
                <Save />
            </ButtonComponent>

        </div>
    )
}

export default TreeViewPopoverHeader;