import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { Viewer, Designer } from "@grapecity/activereports-react";
import { gql } from "@apollo/client";
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { SYS_UPDATE_PANEL_VIEW_REPORT, SYS_ADD_PANEL_VIEW_REPORT, RENAME_SYS_PANELS_VIEW } from '../../apollo/tasks';
import { getTaskStatus } from '../../apollo/queries';




const ReportPanel = ({ panel, panelJSON, reportQuery }) => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const manageViewsPanelsGuid = useSelector(state => state.manageViewsReducer.manageViewsPanelsGuid);
    const panelStateJSON = useSelector(state => state.mainReducer.panelStateJSON);
    const panelsViewGuid = useSelector((state) => state.mainReducer.panelsViewGuid);
    const manageViewsViewName = useSelector((state) => state.manageViewsReducer.manageViewsViewName);
    const refreshedPanelsGuid = useSelector(state => state.mainReducer.refreshedPanelsGuid);
    const isRefreshButtonClicked = useSelector(state => state.mainReducer.isRefreshButtonClicked);
    const isManageEditButtonCLicked = useSelector(state => state.manageViewsReducer.isManageEditButtonCLicked);
    const isManageSaveButtonCLicked = useSelector(state => state.manageViewsReducer.isManageSaveButtonCLicked);
    const isManageVewsSaveAsDialogSaveButtonClicked = useSelector(state => state.manageViewsReducer.isManageVewsSaveAsDialogSaveButtonClicked);
    const isManageVewsRenameDialogSaveButtonClicked = useSelector(state => state.manageViewsReducer.isManageVewsRenameDialogSaveButtonClicked);

    const toastRef = React.useRef(null);
    const viewerRef = React.useRef();
    const designerRef = React.useRef();

    const showToast = (message, type) => {
        if (toastRef.current) {
            toastRef.current.show({
                content: message || '',
                cssClass: `e-toast-${type} custom-toast-position`,
                position: { X: 'Right', YOffset: '1000' },
                animation: { show: { duration: 500 }, hide: { duration: 500 } },
                timeOut: 3000,
            });
        }

    }

    const openReport = async (query, report) => {
        setIsLoading(true);
        const data = await client.query({
            query: gql`
          ${query}
        `});
        report.DataSources[0].ConnectionProperties.ConnectString =
            "jsondata=" + JSON.stringify(data);
        setIsLoading(false);
        return report
    }

    const saveReport = async () => {
        if (designerRef.current) {
            try {
                const reportDefinition = await designerRef.current.getReport();
                const { data } = await client.query({
                    query: SYS_UPDATE_PANEL_VIEW_REPORT,
                    variables: {
                        v_locale: "en",
                        v_sys_panels_views_guid: panelsViewGuid,
                        v_report_json_definition: JSON.parse(JSON.stringify(reportDefinition.definition))
                    }
                })
                if (data.dal_add_task) {
                    const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data.dal_add_task[0].gql_results.tasks_guid } })
                    if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                        showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
                    }
                    else {
                        showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
                    }
                }
            } catch (error) {
                console.error('Error getting report definition:', error);
            }
        }
    }

    const renameReport = async () => {
        try {
            const { data } = await client.query({
                query: RENAME_SYS_PANELS_VIEW,
                variables: {
                    v_locale: "en",
                    v_sys_panels_views_guid: panelsViewGuid,
                    v_sys_owners_guid: "43f2b87a-2946-4a8c-bdbc-78c82341d2ab",
                    v_view_name: manageViewsViewName,
                }
            })
            if (data.dal_add_task) {
                const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data.dal_add_task[0].gql_results.tasks_guid } })
                if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                    showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
                }
                else {
                    showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
                }
            }
        } catch (error) {
            console.error('Error getting report definition:', error);
        }
    }

    const saveAsReport = async () => {
        try {
            let reportDefinition;

            if (isManageEditButtonCLicked && designerRef.current) {
                // Get the report definition from Designer
                reportDefinition = await designerRef.current.getReport();
            } else if (viewerRef.current) {
                // Get the report definition from Viewer
                reportDefinition = JSON.parse(JSON.stringify(panelJSON.report_definition));
            } else {
                console.error("Neither Designer nor Viewer is available for saving the report.");
                return;
            }

            const reportJsonDefinition = isManageEditButtonCLicked
            ? JSON.parse(JSON.stringify(reportDefinition.definition))
            : JSON.parse(JSON.stringify(reportDefinition));

            // Perform the GraphQL query to save the report
            const { data } = await client.query({
                query: SYS_ADD_PANEL_VIEW_REPORT,
                variables: {
                    v_locale: "en",
                    v_sys_panels_guid: panel.sys_panels_guid,
                    v_sys_owners_guid: "43f2b87a-2946-4a8c-bdbc-78c82341d2ab",
                    v_view_name: manageViewsViewName,
                    v_report_json_definition: reportJsonDefinition,
                },
            });

            if (data.dal_add_task) {
                const status = await client.query({
                    query: getTaskStatus,
                    variables: { tasks_guid: data.dal_add_task[0].gql_results.tasks_guid },
                });
                const taskStatus = status?.data?.dal_get_task_status[0];
                const messageType = taskStatus.return_code === "SUCSS" ? "success" : "danger";
                showToast(taskStatus.return_code_locale.message, messageType);
            }
        } catch (error) {
            console.error("Error saving report:", error);
        }
    };



    useEffect(() => {
        if (viewerRef.current) {
            async function showReport(reportViewer) {
                const report = await reportViewer;
                viewerRef.current.Viewer.open(report);
            }
            const reportDefinition = JSON.parse(JSON.stringify(panelJSON.report_definition));
            const reportViewer = openReport(reportQuery, reportDefinition);
            if (reportViewer && panel.sys_panels_guid === refreshedPanelsGuid) {
                showReport(reportViewer);
            }
        }
    }, [isRefreshButtonClicked, panelJSON, refreshedPanelsGuid]);

    useEffect(() => {
        async function showReport(reportViewer) {
            const report = await reportViewer
            viewerRef.current.Viewer.open(report);
        }
        const reportDefinition = JSON.parse(JSON.stringify(panelJSON.report_definition))
        const reportViewer = openReport(reportQuery, reportDefinition)
        if (reportViewer) {
            showReport(reportViewer);
        }


    }, [panelJSON]);

    useEffect(() => {
        if ((isManageEditButtonCLicked && manageViewsPanelsGuid === panel.sys_panels_guid) && panelStateJSON.element_type !== 'map') {
            const report = JSON.parse(JSON.stringify(panelJSON.report_definition))
            designerRef.current.setReport({ definition: report });
        }
    }, [isManageEditButtonCLicked])



    useEffect(() => {
        if ((isManageSaveButtonCLicked && manageViewsPanelsGuid === panel.sys_panels_guid) && panelStateJSON.element_type !== 'map') {
            saveReport()
        }
        return () => {
            dispatch({ type: 'TOGGLE_MANAGE_VIEWS_EDIT_BUTTON', payload: false })
            return dispatch({ type: 'TOGGLE_MANAGE_VIEWS_SAVE_BUTTON', payload: false })
        }

    }, [isManageSaveButtonCLicked]);

    useEffect(() => {
        if ((isManageVewsSaveAsDialogSaveButtonClicked && manageViewsPanelsGuid === panel.sys_panels_guid) && panelStateJSON.element_type !== 'map') {
            saveAsReport()
        }
        return () => {
            return dispatch({ type: 'CLICK_MANAGE_VIEWS_SAVE_AS_DIALOG_SAVE_BUTTON', payload: false })
        }

    }, [isManageVewsSaveAsDialogSaveButtonClicked])

    useEffect(() => {
        if ((isManageVewsRenameDialogSaveButtonClicked && manageViewsPanelsGuid === panel.sys_panels_guid) && panelStateJSON.element_type !== 'map') {
            renameReport()
        }
        return () => {
            return dispatch({ type: 'CLICK_MANAGE_VIEWS_RENAME_DIALOG_SAVE_BUTTON', payload: false })
        }

    }, [isManageVewsRenameDialogSaveButtonClicked])


    return (
        <div id="viewer-host" style={{ height: 'calc(100% - 49px)' }}>
            <ToastComponent ref={toastRef} />
            {
                isManageEditButtonCLicked ?
                    <Designer ref={designerRef} />
                    :
                    <Viewer ref={viewerRef} />
            }

        </div>
    )


}

export default ReportPanel