import React from 'react'
import { Form } from '@patternfly/react-core'
import { useSelector } from 'react-redux'
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns'
import styled from 'styled-components'

import loader from '../../../assets/loader.gif'
import { RENDERED_MAP_TYPE_GUID } from '../../../utils/data'

export const LoadingWrapper = styled('div')`
  padding: 10px;
`

const CreateMapForm = (props) => {
    const mapTypes = useSelector((state) => state.mainReducer.mapTypes)
    const dataSources = useSelector((state) => state.mainReducer.dataSources)

    const updateName = (value) => props.setFormData((prev) => ({ ...prev, maps_name: value }))
    const updateType = (value) => props.setFormData((prev) => ({ ...prev, maps_types_guid: value }))
    const updateRoomSizeW = (value) => props.setFormData((prev) => ({
        ...prev,
        maps_real_size: { ...prev.maps_real_size, roomW: value }
    }))
    const updateRoomSizeH = (value) => props.setFormData((prev) => ({
        ...prev,
        maps_real_size: { ...prev.maps_real_size, roomH: value }
    }))
    const updateDataSource = (value) => props.setFormData((prev) => ({ ...prev, data_sources_guid: value }))

    const fileUpload = (event) => {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])

        reader.onload = function () {
            props.setFormData((prev) => ({ ...prev, maps_img: reader.result }))
        }
        reader.onerror = function (error) {
            console.log('Error: ', error)
        }
    }

    if (props.loading) {
        return (
            <LoadingWrapper>
                <img src={loader} alt="loading..."/>
            </LoadingWrapper>
        )
    }

    return (
        <Form style={{ width: '100%', padding: '10px 15px' }}>
            <section className="dynamic-forms">
                <label style={{ fontSize: '12px', color: '#757575' }}>Name</label>
                <TextBoxComponent
                    change={({ value }) => updateName(value)}
                    value={props.formData.maps_name}
                />
            </section>

            {props.formData.maps_guid
                ?
                <section className="dynamic-forms">
                    <label style={{ fontSize: '12px', color: '#757575' }}>Type</label>
                    <TextBoxComponent
                        enabled={false}
                        value={props.formData.data_sources_name}
                    />
                </section>
                :
                <section className="dynamic-forms">
                    <label style={{ fontSize: '12px', color: '#757575' }}>Type</label>
                    <DropDownListComponent
                        dataSource={mapTypes}
                        fields={{ value: 'maps_types_guid', text: 'maps_types_name' }}
                        select={({ itemData }) => {
                            updateType(itemData.maps_types_guid)
                        }}
                        value={props.formData.maps_types_guid}
                    />
                </section>
            }

            <section className="dynamic-forms">
                <label style={{ fontSize: '12px', color: '#757575' }}>Data source</label>
                <DropDownListComponent
                    dataSource={dataSources}
                    fields={{ value: 'data_sources_guid', text: 'data_sources_name' }}
                    select={({ itemData }) => {
                        updateDataSource(itemData.data_sources_guid)
                    }}
                    value={props.formData.data_sources_guid}
                />
            </section>

            {props.formData.maps_types_guid === RENDERED_MAP_TYPE_GUID &&
                <div>
                    <section className="dynamic-forms">
                        <label style={{ fontSize: '12px', color: '#757575' }}>Room real width (sm)</label>
                        <TextBoxComponent
                            change={({ value }) => updateRoomSizeW(value)}
                            value={props.formData.maps_real_size.roomW}
                        />
                    </section>

                    <section className="dynamic-forms">
                        <label style={{ fontSize: '12px', color: '#757575' }}>Room real height (sm)</label>
                        <TextBoxComponent
                            change={({ value }) => updateRoomSizeH(value)}
                            value={props.formData.maps_real_size.roomH}
                        />
                    </section>
                </div>
            }

            {props.formData.maps_types_guid === RENDERED_MAP_TYPE_GUID ?

                props.formData.maps_img ?
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <input type="file" accept="image/png, image/jpeg, image/svg+xml" onChange={fileUpload}/>
                        <img src={props.formData.maps_img} style={{ width: '70px', height: '70px' }}/>
                    </div>
                    :
                    <input type="file" accept="image/png, image/jpeg, image/svg+xml" onChange={fileUpload}/>
                : <div></div>
            }
        </Form>
    )
}

export default CreateMapForm
