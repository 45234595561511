import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import { URL } from '../constans';
import { Button } from '../Header/styled';
import axios from 'axios';

export const InputModal = ({ setModalIsOpen, targetRow, pass1, pass2, setPass1, setPass2, error }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const s = [user.user_uid, user.tenant.tenants_guid]

    useEffect(() => {
        return () => {
            setPass1('');
            setPass2('');
        };
    }, [])

    const onChangePass1 = (event) => {
        const value = event.target.value
        setPass1(value)
    }

    const onChangePass2 = (event) => {
        const value = event.target.value
        setPass2(value)
    }

    return (
        <Box>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column'
            }}>
                <div style={{ marginBottom: 32 }}>
                    {/*<Typography varinant="h6" style={{ marginRight: 24 }}>Set password</Typography>*/}
                    <TextField
                        onChange={onChangePass1}
                        value={pass1}
                        id="outlined-password-input"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                    />
                </div>
                <div>
                    <Typography varinant="h6" style={{ marginRight: 24 }}>Repeat password</Typography>
                    <FormControl error={error} variant="standard">
                        <TextField
                            onChange={onChangePass2}
                            value={pass2}
                            error={error}
                            id="component-error"
                            type="password"
                            label="Password"
                        />
                    </FormControl>
                </div>
            </div>
            {/*<div style={{ marginTop: 12 }}>*/}
            {/*    /!*<Button onClick={() => setModalIsOpen(false)}>Close modal</Button>*!/*/}
            {/*    <Button onClick={applyHandleClick}>Apply</Button>*/}
            {/*</div>*/}
        </Box>
    );
};