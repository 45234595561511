import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography } from '@mui/material';

export const DropDown = ({ role, setRole, roles, accountPage, targetRow }) => {
    const [dropdownDisabled, setDropdownDisabled ] = React.useState(false)
    const uniqueRoles = roles.filter(
        (obj, index) =>
            roles.findIndex(
                (item) => item.role_name === obj.role_name && item.tenants_guid === obj.tenants_guid
            ) === index
    )

    const handleChange = (event) => {
        setRole(event.target.value);
    }

    React.useEffect(() => {
        if (targetRow) {
            setRole(targetRow.roleId)
            if (accountPage) {
                setDropdownDisabled(true)
            }
        }
        return () => {
            setRole('');
        };
    }, [])

    return (
        <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    disabled={dropdownDisabled}
                    value={role}
                    label="Role"
                    onChange={handleChange}
                >
                    {uniqueRoles?.map(item => (
                        <MenuItem value={item.roles_guid}>{item.role_name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}