import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from '@mui/material/Button';
import {useApolloClient} from "@apollo/react-hooks";
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

import '../components/admin/table.css'
import SynModal from "../components/modals/modal";
import CreateMapForm from "../components/forms/createMapForm";
import LinkMapAndRegionGroupForm from "../components/forms/linkMapAndRegionGroupForm";
import {
    addMaps,
    deleteMaps,
    updateMaps,
    addMapsView,
    addMapsRegionsGroups,
    deleteMapsRegionsGroupsByMapAndGroup
} from "../apollo/mutations";
import {
    getDataSourcesList,
    getMap,
    getMapRegionsGroups,
    getMaps,
    getTaskStatus,
    getUnallocatedRegionsGroups
} from "../apollo/queries";
import {RENDERED_MAP_TYPE_GUID} from "../../utils/data";

const shouldUpdate = {
    current: true
}

const ManageMaps = () => {
    const toastRef = useRef(null)
    const maps = useSelector((state) => state.mainReducer.maps);
    const unallocatedRegionGroups = useSelector((state) => state.mainReducer.unallocatedRegionGroups);
    const allocatedRegionGroups = useSelector((state) => state.mainReducer.allocatedRegionGroups);

    const client = useApolloClient();
    const dispatch = useDispatch();

    const [mapCreationModalVisibility, setMapCreationModalVisibility] = useState(false);
    const [mapData, setMapData] = useState({maps_guid: null, maps_name: "", maps_types_guid: "", maps_img: "", maps_real_size: "", data_sources_guid: null});
    const [loadingModalData, setLoadingModalData] = useState(false)

    const [regionGroupLinkModalVisibility, setRegionGroupLinkModalVisibility] = useState(false);
    const [mapRegionGroupAllocationData, setMapRegionGroupAllocationData] = useState({maps_guid: null, unsavedUnallocated: null, unsavedAllocated: null});

    const locale = localStorage.getItem('locale');
    const user = JSON.parse(localStorage.getItem('user'));

    const clearMapData = () => {
        setMapCreationModalVisibility(false);
        setMapData({maps_guid: "", maps_name: "", maps_types_guid: "", maps_img: "", maps_real_size: "", data_sources_guid: null})
    }

    // const checkTaskStatus = async (tasks_guid, title, actionType, data, addGuid, nextFunc) => { // todo add time delay
    //     let status = '';
    //     while (status === 'CREATED' || status === 'RECREATED' || status === 'RUNNING' || !status) {
    //
    //         const result = client.query({query: getTaskStatus, variables: {tasks_guid}})
    //         status = result?.data?.dal_get_task_status[0].gql_results?.task_status;
    //
    //         if (status === 'COMPLETED') {
    //             if (addGuid) dispatch({
    //                 type: actionType,
    //                 data: {...data, maps_guid: result.data.dal_get_task_status[0].gql_results.gql_results.maps_guid}
    //             });
    //             else dispatch({type: actionType, data});
    //
    //             clearMapData();
    //             toast.success(title);
    //         } else if (status === 'FAILED') {
    //             toast.error(`Something went wrong!`);
    //         }
    //     }
    // };
        // 'CREATED', 'RECREATED', 'CANCELED', 'RUNNING', 'PAUSED', 'FAILED', 'COMPLETE


    const onMapAdd = () => {
        setMapCreationModalVisibility(true)
    };

    const onMapSave = () => {
        var img = new Image();

        img.src = mapData.maps_img;
        // img.onload = async function () {
        //     await saveMapData({width: Number(img.width), height: Number(img.height)});
        // };

      const setMap = async () => {
            await saveMapData({width: Number(img.width), height: Number(img.height)});
        };

        setMap()
    }

    const saveMapData = async (imgSize) => {
        console.log(mapData)
        if(mapData.maps_guid) {
            const {data} = await client.mutate({
                mutation: updateMaps,
                variables: {
                    maps_guid: mapData.maps_guid,
                    maps_name: mapData.maps_name,
                    maps_types_guid: mapData.maps_types_guid,
                    maps_img: mapData.maps_img ? mapData.maps_img : "",
                    maps_real_size: Object.keys(mapData.maps_real_size).length > 0 && typeof mapData.maps_real_size === 'object' ?
                        JSON.stringify(mapData.maps_real_size) : "",
                    data_sources_guid: mapData.data_sources_guid,
                    locale: locale ? locale : "en"
                }
            })
            if(data?.insert_tasks_tasks?.returning) {
                const status = await client.query({
                    query: getTaskStatus,
                    variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}
                })
                if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                    // todo
                    // await onMapViewSave('Map was edited', mapData.maps_guid, imgSize);
                    clearMapData();
                } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                    showToast(status?.data?.dal_get_task_status[0].return_code_locale?.message || 'Something went wrong!', 'danger')
                }
                // checkTaskStatus(data?.insert_tasks_tasks?.returning[0].tasks_guid, 'Map was updated', 'EDIT_MAP', mapData, false);
            }
        } else {
            console.log('addMaps')
            const {data} = await client.mutate({
                mutation: addMaps,
                variables: {
                    maps_guid: mapData.maps_guid,
                    maps_name: mapData.maps_name,
                    maps_types_guid: mapData.maps_types_guid,
                    maps_img: mapData.maps_img ? mapData.maps_img : "",
                    maps_real_size: Object.keys(mapData.maps_real_size).length > 0 && typeof mapData.maps_real_size === 'object' ?
                        JSON.stringify(mapData.maps_real_size) : "",
                    data_sources_guid: mapData.data_sources_guid,
                    locale: locale ? locale : "en"
                }
            })
            if(data?.insert_tasks_tasks?.returning) {
                const status = await client.query({
                    query: getTaskStatus,
                    variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}
                })
                if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                    await onMapViewSave('Map was created', status?.data?.dal_get_task_status[0].gql_results?.gql_results?.maps_guid, imgSize);
                    clearMapData();
                } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                    showToast(status?.data?.dal_get_task_status[0].return_code_locale?.message || 'Something went wrong!', 'danger')
                }
                // checkTaskStatus(data?.insert_tasks_tasks?.returning[0].tasks_guid, 'Map was created', 'ADD_MAP', mapData, true, 'onMapViewSave');
            }
        }

        _getAllMaps()
    };

    const onMapViewSave = async (message, maps_guid, imgSize) => {
        // todo update MapView after editing map data
        // if(maps_guid) {
        //
        // } else {
        //
        // }

        const {data} = await client.mutate({
            mutation: addMapsView,
            variables: {
                maps_views_guid: null,
                maps_views_name: mapData.maps_name,
                maps_views_centroid: mapData.maps_types_guid === RENDERED_MAP_TYPE_GUID ? 'point ('+ imgSize.height / 2 + ' ' + imgSize.width / 2 + ')' : 'point (39.748359 -105.004311)',
                maps_views_zoom: mapData.maps_types_guid === RENDERED_MAP_TYPE_GUID ? 0 : 15,
                maps_guid:  maps_guid,
                locale: locale ? locale : "en"
            }
        })
        if(data?.insert_tasks_tasks?.returning) {
            const status = await client.query({
                query: getTaskStatus,
                variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}
            })
            if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                showToast(message, 'success')

            } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {

                showToast(status?.data?.dal_get_task_status[0].return_code_locale?.message || 'Something went wrong!', 'danger')
            }
        }
    };

    const onMapEdit = (data) => {
        clearMapData();
        _getMap(data.maps_guid);
        setMapCreationModalVisibility(true)
    };

    const onMapDelete = async (maps_guid) => {
        const {data} = await client.mutate({
            mutation: deleteMaps,
            variables: {
                maps_guid: maps_guid,
                locale: locale ? locale : "en",
            }
        })
        if(data?.insert_tasks_tasks?.returning) {
            const status = await client.query({query: getTaskStatus, variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}})
            if(status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED'){
                dispatch({type: 'DELETE_MAP', data: maps_guid});
                showToast(status?.data?.dal_get_task_status[0].return_code_locale?.message, 'success')

            }
            else if(status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {

                showToast(status?.data?.dal_get_task_status[0].return_code_locale?.message || 'Something went wrong!', 'danger')
            }
        }
    };

    const _getAllMaps = async () => {
        try{
            const {data, errors} = await client.query({query: getMaps, variables: {maps_types_guid: null, locale: locale ? locale : "en"}});

            if(data){
                let mapsList = [];
                for(let i=0; i<data.dal_get_maps_list2.length; i++){
                    mapsList.push({...data.dal_get_maps_list2[i]})
                }
                dispatch({type: 'SET_MAPS', data: mapsList});
            }

            if(errors?.length > 0){
                throw new Error(errors[0].message)
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }

    const _getMap = async (maps_guid) => {
        try{
            setLoadingModalData(true)
            const {data, errors, loading} = await client.query({query: getMap, variables: {maps_guid, tenants_guid: user.tenant.tenants_guid, users_guid: user.user_uid, locale: locale ? locale : "en"}});
            // console.log('data', data)
            // console.log('loading', loading)
            if(data.dal_get_maps && data.dal_get_maps[0] && data.dal_get_maps[0].gql_results )  {
                setLoadingModalData(false)
                setMapData({...data.dal_get_maps[0].gql_results, maps_real_size: data.dal_get_maps[0].gql_results.maps_real_size ? JSON.parse(data.dal_get_maps[0].gql_results.maps_real_size.replaceAll('\\', '')) : ""});
            }

            if(errors?.length > 0){
                throw new Error(errors[0].message)
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }

    const _getDataSourcesList = async () => {
        try {
            const { data, errors } = await client.query({
                query: getDataSourcesList,
                variables: { locale: locale ? locale : 'en' }
            })
            if (data) dispatch({ type: 'SET_DATA_SOURCES', data: data.dal_get_data_sources_list })

            if (errors?.length > 0) {
                throw new Error(errors[0].message)
            }
        } catch (e) {
            console.log(e.message)
        }
    }

    const _getAllocatedRegionsGroups = async (maps_guid) => {
        try {
            dispatch({type: 'SET_ALLOCATED_REGIONS_GROUPS', data: null})
            const {data, errors} = await client.query({query: getMapRegionsGroups, variables: {maps_guid, locale: locale ? locale : "en"}});
            if(data?.dal_get_regions_groups_list2) {
                setMapRegionGroupAllocationData(prevState => ({...prevState, unsavedAllocated: data?.dal_get_regions_groups_list2}));
                dispatch({type: 'SET_ALLOCATED_REGIONS_GROUPS', data: data.dal_get_regions_groups_list2});
                // setShouldUpdate(true)
            }

            if(errors?.length > 0){
                throw new Error(errors[0].message)
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }

    const _getUnallocatedRegionsGroups = async (maps_guid) => {
        try {
            dispatch({type: 'SET_UNALLOCATED_REGIONS_GROUPS', data: null})
            const {data, errors} = await client.query({query: getUnallocatedRegionsGroups, variables: {maps_guid, locale: locale ? locale : "en"}});
            if(data?.dal_get_regions_groups_unallocated_list2) {
                setMapRegionGroupAllocationData(prevState => ({...prevState, unsavedUnallocated: data.dal_get_regions_groups_unallocated_list2}));
                dispatch({type: 'SET_UNALLOCATED_REGIONS_GROUPS', data: data.dal_get_regions_groups_unallocated_list2})
                // setShouldUpdate(true)
            }
            if(errors?.length > 0){
                throw new Error(errors[0].message)
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }

    const onAssociateWithGroups = (maps_guid) => {
        setMapRegionGroupAllocationData(prevState => ({...prevState, maps_guid}));
        // setShouldUpdate(true)
        _getAllocatedRegionsGroups(maps_guid);
        _getUnallocatedRegionsGroups(maps_guid);

        setRegionGroupLinkModalVisibility(true);
    }

    const onLinkModalClose = (maps_guid) => {
        setMapRegionGroupAllocationData({maps_guid: null, unsavedAllocated: null, unsavedUnallocated: null});
        // setShouldUpdate(true)
        setRegionGroupLinkModalVisibility(false);
    }

    const onLinkSave = async () => {

        if (!shouldUpdate.current) {
            showToast('No changes', 'danger')
        }
        else {
            if(mapRegionGroupAllocationData.unsavedUnallocated.length === 0) {
                // все группы привязаны к карте
                // works
                unallocatedRegionGroups.forEach(el => addMapRegionGroupHandler(mapRegionGroupAllocationData.maps_guid, el.regions_groups_guid));
            }

            if(mapRegionGroupAllocationData.unsavedAllocated.length === 0) {
                // все группы отвязаны от карты
                // works
                deleteMapRegionGroupHandler(mapRegionGroupAllocationData.maps_guid, null)
                // allocatedRegionGroups.forEach(el => deleteMapRegionGroupHandler(mapRegionGroupAllocationData.maps_guid, el.regions_groups_guid));
            }

            if(mapRegionGroupAllocationData.unsavedUnallocated.length > 0 && mapRegionGroupAllocationData.unsavedAllocated.length > 0) {
                const newAddedGroups = mapRegionGroupAllocationData.unsavedAllocated.filter(el => {
                    return !allocatedRegionGroups.find(checked => el.regions_groups_guid === checked.regions_groups_guid);
                });
                newAddedGroups.length > 0 && newAddedGroups.forEach(el => addMapRegionGroupHandler(mapRegionGroupAllocationData.maps_guid, el.regions_groups_guid));
                //
                const newDeletedGroups = mapRegionGroupAllocationData.unsavedUnallocated.filter(el => {
                    return !unallocatedRegionGroups.find(checked => el.regions_groups_guid === checked.regions_groups_guid);
                });
                newDeletedGroups.length > 0 && newDeletedGroups.forEach(el => deleteMapRegionGroupHandler(mapRegionGroupAllocationData.maps_guid, el.regions_groups_guid));
                // showToast('Map data was saved', 'success')
            }
            // setShouldUpdate(false)
            shouldUpdate.current = false
        }
    }

    // привязать регион к карте
    const addMapRegionGroupHandler = async (maps_guid, regions_groups_guid) => {
        const {data} = await client.mutate({
            mutation: addMapsRegionsGroups,
            variables: {
                maps_regions_groups_guid: null,
                maps_guid: maps_guid,
                regions_groups_guid: regions_groups_guid,
                locale: locale ? locale : "en",
            }
        })
        if(data?.insert_tasks_tasks?.returning) {
            const status = await client.query({query: getTaskStatus, variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}})
            if(status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED'){
                // console.log('status', status)
                showToast(status?.data?.dal_get_task_status[0].return_code_locale?.message, 'success')
            }
            else if(status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {

                if (status?.data?.dal_get_task_status[0].return_code_locale?.message) {
                    showToast(status?.data?.dal_get_task_status[0].return_code_locale?.message, 'danger')
                }
            }
        }
    }

    // отвязать регион от карты
    const deleteMapRegionGroupHandler = async (maps_guid, regions_groups_guid) => {
        const {data} = await client.mutate({
            mutation: deleteMapsRegionsGroupsByMapAndGroup,
            variables: {
                maps_guid,
                regions_groups_guid,
                locale: locale ? locale : "en",
            }
        })
        if(data?.insert_tasks_tasks?.returning) {
            const status = await client.query({query: getTaskStatus, variables: {tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid}})
            if(status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED'){
                showToast(status?.data?.dal_get_task_status[0].return_code_locale?.message, 'success')
            }
            else if(status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale?.message || 'Something went wrong!', 'danger')
            }
        }
    }

    useEffect(() => {
        _getAllMaps();
        _getDataSourcesList();
    }, []);

    const showToast = (message, type) => {
        toastRef.current.show({
          content: message,
          cssClass: `e-toast-${type}`,
          position: { X: 'Right', Y: 'Top' },
          animation: { show: { duration: 500 }, hide: { duration: 500 } },
          timeOut: 3000,
        });
      }

    return (
        <div style={{marginTop: '15px'}}>
            <Button variant="contained" style={{marginLeft: '10px'}} onClick={onMapAdd}>Add New Map</Button>

            <p style={{marginLeft: '10px', marginTop: '10px', fontWeight: 'bold'}}>Maps</p>
            <table className="table">
                <thead className="table_head">
                <tr role="row">
                    <th role="columnheader" scope="col">ID</th>
                    <th role="columnheader" scope="col">Name</th>
                    <th role="columnheader" scope="col">Type</th>
                </tr>
                </thead>

                <tbody className="table_body" role="rowgroup">

                {maps.map((map, idx) =>
                    <tr role="row" key={`${map.maps_guid}_${idx}`}>
                        <td role="cell">{map.maps_guid}</td>
                        <td role="cell">{map.maps_name}</td>
                        <td role="cell">{map.maps_types_name}</td>

                        {/*<td role="cell" >{mapTypes.find(el => el.maps_types_guid === map.maps_types_guid) ?*/}
                        {/*    mapTypes.find(el => el.maps_types_guid === map.maps_types_guid).maps_types_default_display_name : ''}</td>*/}

                        <td className="" role="cell">
                            <div className="table_col__btn_wrapper">
                                <Button variant="contained" onClick={() => onMapEdit(map)}>Edit</Button>
                                <Button variant="contained" onClick={() => onAssociateWithGroups(map.maps_guid)}>Manage
                                    region groups</Button>
                                <Button variant="contained" color="error"
                                        onClick={() => onMapDelete(map.maps_guid)}>Delete</Button>
                            </div>
                        </td>
                    </tr>

                )}

                {maps.length === 0 &&
                    <tr role="row" >
                        <td colSpan="4" style={{textAlign: 'center'}}>NO MAPS</td>
                    </tr>
                }
                </tbody>
            </table>

            <ToastComponent ref={toastRef} />

            <SynModal
                shouldUpdate={shouldUpdate.current}
                title="Map"
                loading={loadingModalData}
                openModal={mapCreationModalVisibility}
                setOpenModal={setMapCreationModalVisibility}
                save={onMapSave}
                cancel={clearMapData}
                content={(
                    <CreateMapForm
                        loading={loadingModalData}
                        formData={mapData}
                        setFormData={setMapData}
                    />
                )}
            />

            <SynModal
                shouldUpdate={shouldUpdate.current}
                title={mapRegionGroupAllocationData.maps_guid ? maps.find(el => el.maps_guid === mapRegionGroupAllocationData.maps_guid).maps_name : 'Map'}
                openModal={regionGroupLinkModalVisibility}
                setOpenModal={setRegionGroupLinkModalVisibility}
                save={onLinkSave}
                cancel={onLinkModalClose}
                content={(
                    <LinkMapAndRegionGroupForm
                        shouldUpdate={shouldUpdate}
                        mapRegionGroupAllocationData={mapRegionGroupAllocationData}
                        setMapRegionGroupAllocationData={setMapRegionGroupAllocationData}
                    />
                )}
            />
        </div>
    )
}

export default ManageMaps;
