import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useContext, useEffect, useRef, useState } from 'react';
import { OptionsValueContext, SidebarContext } from '../../pages/dynamicScreens/Main/context';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { SENSORS } from '../../utils/data';
import MapIcon from '@mui/icons-material/Map';
import Box from '@mui/material/Box';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import MuiButton from '@mui/material/Button';
import { SettingsStaticMap } from './components/SettingsStaticMap';
import { querySelector } from '@syncfusion/ej2-maps';
import { GlobalTheme } from '../../context/GlobalTheme';

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
}
export const SettingsPanel = ({ settingsState, setSettingsState, filterState, setFilterState }) => {
    const forceUpdate = useForceUpdate()
    const globalTheme = useContext(GlobalTheme)

    const toggleDrawer =
        (anchor, open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    (event.key === 'Tab' ||
                        event.key === 'Shift')
                ) {
                    return;
                }

                if (filterState) {
                    setFilterState(false)
                }

                setSettingsState({ ...settingsState, [anchor]: open });

                // if (barsOpen.settings) {
                //     setState({ ...state, [anchor]: open });
                //     setBarsOpen((prevState) => ({
                //         ...prevState,
                //         filter: false
                //     }))
                // }

            };

    const sidebarContext = useContext(SidebarContext)

    const showPointMode = useSelector((state) => state.mainReducer.showPointMode); // 1-all points, 2-only final point
    const showAnimationDotMode = useSelector((state) => state.mainReducer.animatedPointsMode);
    const sensorsVisible = useSelector((state) => state.mainReducer.visibleSensorTypes);
    const showRegionsPicker = useSelector((state) => state.mainReducer.showRegionsPicker);

    const [isOpen, setOpen] = useState();
    const dispatch = useDispatch();

    let sidebarRef = useRef();
    // useEffect(() => setOpen(isExpanded), [isExpanded]);

    useEffect(() => {
        if (isOpen === true) sidebarRef.current.show();
    }, [isOpen]);

    let editShowPointMode = (event) => {
        dispatch({ type: 'EDIT_SHOW_POINT_MODE', data: event });
    }

    let editAnimationMode = (event) => {
        dispatch({ type: 'EDIT_MODE', data: event });
    }

    let editRegionsPickerVisibility = () => {
        dispatch({ type: 'EDIT_REGIONS_PICKER_VISIBILITY' });
    }

    let selectVisibleSensorType = (event) => {
        const label = event.target.id;
        dispatch({ type: 'EDIT_SENSOR_TYPE_VISIBILITY', data: { ...sensorsVisible, [label]: !sensorsVisible[label] } });
    }

    let onmouseover = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const editShowMarkers = (event) => {
        sidebarContext.setShowMarkers(event)
    }

    useEffect(() => {
        // forceUpdate()
        sidebarContext.forceUpdate = forceUpdate
    }, [])

    const mapCaptions = [
        {
            label: sidebarContext.panelCaptionStaticMap,
            value: sidebarContext.panelCaptionStaticMap,
        },
        {
            label: sidebarContext.panelCaptionMap,
            value: sidebarContext.panelCaptionMap,
        },
    ]

    const [activeMapSettings, setActiveMapSettings] = useState(sidebarContext.panelCaptionMap || sidebarContext.panelCaptionStaticMap)

    useEffect(() => {
        setActiveMapSettings(sidebarContext.panelCaptionMap)
    },[sidebarContext.panelCaptionMap])
    // console.log('sidebarContext.regionsTypes', sidebarContext.regionsTypes)
    // console.log('sidebarContext.openedPanel?.regionTypes', sidebarContext.openedPanel?.regionTypes)
    // console.log('sidebarContext.regionsShow', sidebarContext.regionsShow)

    useEffect(() => {
        sidebarContext.settingsIsOpen = settingsState.right
    })

    return (
        <div>
            {(['right']).map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button style={{ minWidth: 40 }} onClick={toggleDrawer(anchor, true)}>
                        <MapIcon style={{ fill: globalTheme[globalTheme.activeTheme]?.svgFill }} />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={settingsState[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {!!sidebarContext.panelCaptionMap && !!sidebarContext.panelCaptionStaticMap && (
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={mapCaptions}
                                defaultValue={sidebarContext.panelCaptionMap}
                                sx={{ width: 280, margin: '0 auto', marginTop: 2, paddingTop: 8 }}
                                onChange={(event, value) => {
                                    event.preventDefault()
                                    setActiveMapSettings(value?.value)
                                    forceUpdate()
                                }}
                                renderInput={(params) => <TextField {...params} label="Panels"/>}
                            />
                        )}
                        {!!sidebarContext.panelCaptionMap && activeMapSettings === sidebarContext.panelCaptionMap ? (
                            <div onMouseDown={onmouseover} className="drawer-wrapper"
                                 style={{ width: 280, padding: '64px 20px 0px' }}>
                                <div className="sidebar-header">
                                    <span className="sidebar-settings-title">Settings</span>
                                </div>

                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    value={showAnimationDotMode}
                                >
                                    <FormControlLabel value={1} control={<Radio/>} label="Default dot mode"
                                                      onClick={() => editAnimationMode(1)}/>
                                    <FormControlLabel value={2} control={<Radio/>} label="Smoothing dot mode"
                                                      onClick={() => editAnimationMode(2)}/>
                                </RadioGroup>

                                <hr/>

                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={1}
                                    value={showPointMode}
                                >
                                    <FormControlLabel value={1} control={<Radio/>} label="Show all dots"
                                                      onClick={() => editShowPointMode(1)}/>
                                    {showPointMode === 1 && (
                                        <div style={{ paddingLeft: '30px', maxWidth: 'min-content' }}>
                                            {Object.keys(sensorsVisible).map(el => (
                                                <FormControlLabel
                                                    key={el}
                                                    control={(
                                                        <Checkbox id={el}
                                                                  checked={!!sensorsVisible[el]}
                                                                  onChange={selectVisibleSensorType}
                                                        />
                                                    )}
                                                    label={SENSORS[el].title}
                                                    style={{ color: SENSORS[el].color }}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    <FormControlLabel value={2} control={<Radio/>} label="Show only final dot"
                                                      onClick={() => editShowPointMode(2)}/>
                                </RadioGroup>

                                <hr/>
                                {/*1111*/}
                                {sidebarContext.showMarkersOnPanel && (
                                    <>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            value={showAnimationDotMode}
                                        >
                                            <FormControlLabel checked={sidebarContext.showMarkers}
                                                              value={sidebarContext.showMarkers} control={<Radio/>}
                                                              label="Show markers"
                                                              onClick={() => {
                                                                  forceUpdate()
                                                                  return editShowMarkers(true)
                                                              }}/>
                                            <FormControlLabel checked={!sidebarContext.showMarkers}
                                                              value={sidebarContext.showMarkers} control={<Radio/>}
                                                              label="Hide markers"
                                                              onClick={() => {
                                                                  forceUpdate()
                                                                  return editShowMarkers(false)
                                                              }}/>
                                        </RadioGroup>

                                        <hr/>
                                    </>
                                )}

                                {/*{regionsPickerShow &&*/}
                                {/*    <button className='regions-show-btn' onClick={editRegionsPickerVisibility}>{showRegionsPicker ? 'Show Regions Picker' : 'Hide Regions Picker'}</button>*/}
                                {/*}*/}

                                {sidebarContext.openedPanel?.regionTypes && sidebarContext.regionsShow?.length > 0 &&
                                    <div className='map-regions-checkbox-wrapper' style={{ height: sidebarContext.mapWrapperRef?.current?.offsetHeight - 115 || '100%', right: sidebarContext.openedPanel.settings ? 210 : 10 }}>
                                        {sidebarContext.regionsShow.length > 0 && sidebarContext.regionsShow.map(regionGroup => {
                                            const regionsGroupGuid = regionGroup.regions?.filter(item => item.regions_types_guid)
                                            const activeRegions = regionsGroupGuid?.filter(item => (item.regions_types_guid === sidebarContext.activeRegionGroupId))
                                            const hasRegions = activeRegions.some(item => {
                                                let hasRegion = false
                                                regionGroup.regions.forEach(regionGroupItem => {
                                                    if (regionGroupItem.regions_types_guid === item.regions_types_guid) {
                                                        hasRegion = true
                                                    }
                                                })

                                                return hasRegion
                                            })

                                            const isRendering = sidebarContext.activeRegionName && !hasRegions

                                            return isRendering ? null :  (
                                                <div key={regionGroup.regions_groups_guid}>
                                                    <FormControlLabel
                                                        label={regionGroup.regions_groups_name}
                                                        control={(
                                                            <Checkbox id={regionGroup.regions_groups_guid}
                                                                      size="small"
                                                                      checked={regionGroup.targetRow}
                                                                      onClick={forceUpdate}
                                                                      onChange={sidebarContext.regionGroupCheck}
                                                            />
                                                        )}
                                                    />
                                                    {regionGroup.targetRow === true && <Box sx={{ display: 'flex', flexDirection: 'column', ml: 5 }}>
                                                        {regionGroup.regions.map(region =>
                                                            <div key={region.regions_guid}>
                                                                <FormControlLabel
                                                                    label={region.regions_name}
                                                                    control={(
                                                                        <Checkbox id={region.regions_guid}
                                                                                  size="small" checked={region.targetRow}
                                                                                  onChange={(event) => {
                                                                                      forceUpdate()
                                                                                      sidebarContext.regionCheck(event, regionGroup, region)
                                                                                  }}
                                                                        />
                                                                    )}
                                                                />
                                                            </div>
                                                        )}
                                                    </Box>}
                                                </div>
                                            )
                                        })}
                                    </div>
                                }

                                {/* фильтрация регионов по типу */}
                                {sidebarContext.openedPanel?.regionTypes && sidebarContext.regionsShow?.length > 0 &&
                                    <div onClick={forceUpdate}  style={{ right: sidebarContext.openedPanel.settings ? 210 : 10 }} className='map-types-dropdown'>
                                        <section style={{ position: 'relative' }} className='dynamic-forms' onClick={forceUpdate}>
                                            <label style={{ fontSize: '12px', color: '#757575', paddingBottom: 12 }}>Region types</label>
                                            <div style={{ display: 'flex' }} onClick={forceUpdate} onChange={forceUpdate} onFocusCapture={forceUpdate} onFocus={forceUpdate}>
                                                <DropDownListComponent
                                                    onClick={forceUpdate}
                                                    dataSource={sidebarContext.regionsTypes}
                                                    fields={{ value: 'regions_types_guid', text: 'region_type_name' }}
                                                    select={({ itemData }) => {
                                                        forceUpdate()
                                                        sidebarContext.onMapTypeChange(itemData.regions_types_guid, itemData.region_type_name);
                                                    }}
                                                    value={sidebarContext.currentMap.regions_types_guid}
                                                />
                                                {/*<button className="dropdown-value-clean-btn" onClick={cleanRegionTypeFilter}> x </button>*/}
                                            </div>
                                        </section>
                                    </div>
                                }
                                {/*{sidebarContext.regionsOfMaps ? sidebarContext.regionsOfMaps : null }*/}
                            </div>
                        )  :<SettingsStaticMap/>}
                        {/*{activeMapSettings === sidebarContext.panelCaptionStaticMap && <SettingsStaticMap/>}*/}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}