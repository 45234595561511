import React, { useRef, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useForm, Controller } from "react-hook-form";


const Error = ({ children }) => <div style={{ color: 'red' }}>{children}</div>;

const MapDialogContent = () => {
    const dispatch = useDispatch();

    const drawnPolygonName = useSelector(state => state.treeViewReducer.drawnPolygonName);
    const isTreeViewSaveButtonClicked = useSelector(state => state.treeViewReducer.isTreeViewSaveButtonClicked);

    const { handleSubmit, control, formState: { errors }, reset } = useForm();

    const onSubmit = (data) => {
        dispatch({ type: 'CHANGE_POLYGON_NAME', payload: data.polygonName })
        dispatch({ type: 'POLYGON_DRAW_PROCESS', payload: false })
        dispatch({ type: 'CLICK_TREE_VIEW_DIALOG_SAVE_BUTTON', payload: true })
        dispatch({ type: 'TOGGLE_MAP_DIALOG', payload: false })
        reset();
    }

    const closeMapDialog = () => {
        dispatch({ type: 'TOGGLE_MAP_DIALOG', payload: false })
        dispatch({ type: 'POLYGON_DRAW_PROCESS', payload: false })
        reset();
    }



    return (
        <div className="wrapper">
            <section>
                <label className='my-2'>Polygon Name</label>
                <Controller
                    name="polygonName"
                    control={control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field }) => (
                        <TextBoxComponent
                            placeholder="Enter Polygon Name"
                            // floatLabelType="Auto"
                            change={({ value }) => field.onChange(value)}
                            value={field.value}
                        />
                    )}
                />
                {errors.polygonName && <Error>This field is required</Error>}
            </section>
            <div className="d-flex justify-content-between my-2">
                <ButtonComponent onClick={handleSubmit(onSubmit)} type="submit" cssClass='e-primary'>
                    Save
                </ButtonComponent>
                <ButtonComponent onClick={closeMapDialog} cssClass='e-outline'>
                    Cancel
                </ButtonComponent>
            </div>
        </div>
    )
}

export default MapDialogContent