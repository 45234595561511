import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchPolygons, useInitializeMap } from '../hooks';
import MapDialogComponent from './MapDialogComponent';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

const Map = ({ panel, panelJSON, controls, animalsData }) => {
    const toastRef = React.useRef(null);
    const user = JSON.parse(localStorage.getItem('user'));
    const dispatch = useDispatch();
    const isDrawPolygon = useSelector(state => state.treeViewReducer.isDrawPolygon);
    const isTreeViewToggled = useSelector(state => state.treeViewReducer.isTreeViewToggled);
    const polygonsForMapList = useFetchPolygons(panel, user, isTreeViewToggled);

    const mapRef = useInitializeMap(panel,panelJSON, isDrawPolygon, dispatch, controls, animalsData, polygonsForMapList, isTreeViewToggled, toastRef);

    return (
        <>
            <ToastComponent ref={toastRef} />
            <MapDialogComponent />
            <div ref={mapRef} style={{ height: '100%' }} />
        </>
    );
};

export default Map;
