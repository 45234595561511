import React, { useState, useEffect, useContext } from 'react'
import Sidebar from '../../../components/Sidebar'
import { Box, Container } from '@mui/material'
import GridLayout from "react-grid-layout";
import { TenantsList, UsersList } from '../index';
import { GlobalTheme } from '../../../context/GlobalTheme'
import { SelectedRowContextProvider } from '../../../context/SelectedRowContext';


export const TenantsGrid = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const selectedTenant = JSON.parse(localStorage.getItem('selectedTenant'))
    if(!selectedTenant){
        localStorage.setItem("selectedTenant", JSON.stringify(user.tenant.tenants_guid))
    }
    const globalTheme = useContext(GlobalTheme)

    return (
        <>
            <SelectedRowContextProvider>
                <Sidebar>
                    <Box component="tenants-grid" className="main-wrapper">
                        <Container maxWidth="false">
                            <div className="control-section"
                                style={{ marginTop: '50px' }}
                            >
                                <div className="control-section">
                                    <GridLayout
                                        cols={12}
                                        rowHeight={30}
                                        width={1200}
                                    >
                                        <div
                                            style={{ border: `1px solid ${globalTheme[globalTheme.activeTheme]?.headerBackground}` }}
                                            key="a"
                                            data-grid={{
                                                x: 0,
                                                y: 0,
                                                w: 12,
                                                h: 12,
                                                static: true
                                            }}
                                        >
                                            <TenantsList />
                                        </div>
                                        <div
                                            style={{ border: `1px solid ${globalTheme[globalTheme.activeTheme]?.headerBackground}` }}
                                            key="b"
                                            data-grid={{
                                                x: 0,
                                                y: 12,
                                                w: 12,
                                                h: 12,
                                                static: true
                                            }}
                                        >
                                            <UsersList />
                                        </div>


                                    </GridLayout>
                                </div>
                            </div>
                        </Container>

                    </Box>
                </Sidebar>
            </SelectedRowContextProvider>
        </>
    )
}