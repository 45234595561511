import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useApolloClient } from '@apollo/react-hooks';
import Checkbox from '@mui/material/Checkbox';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';

import Sidebar from '../../components/Sidebar';
import { GET_USER_NAME_EMAIL, GET_USERS2, GET_ROLES } from '../../apollo/queries';
import { Header } from './Header';
import { Modal } from './Modal';
import { Button } from './Header/styled';
import { ModalContainer } from './styled';
import axios from 'axios';
import { ConsentModal } from './ConsentModal';
import { EditModal } from './EditModal';
import { InputModal } from './InputModal';
import { CreateUser } from './CreateUser';
// import { URL } from './constans';
import SynModal from '../../mapping/components/modals/modal';

export const UsersList = () => {
    const URL = process.env.RACT_APP_USERLIST;
    const toastRef = useRef(null);
    const client = useApolloClient();
    const user = JSON.parse(localStorage.getItem('user'))
    const [tenantUserGuid, tenantGuid] = [user.user_uid, user.tenant.tenants_guid]
    const [userNameEmail, setUserNameEmail] = useState('')
    const [targetValue, setTargetValue] = useState(null)

    const [users, setUsers] = useState([])

    const [pass1, setPass1] = useState('')
    const [pass2, setPass2] = useState('')
    const [error, setError] = useState(false)
    const [error2, setError2] = useState(false)

    const [modalIsOpenPassword, setModalIsOpenPassword] = useState(false)
    const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false)
    const [modalIsOpenNewUser, setModalIsOpenNewUser] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const [userName, setUserName] = useState('')
    const [userSecret, setUserSecret] = useState('1234')
    const [roleIsUser, setRoleIsUser] = useState(false)
    const [roles, setRoles] = useState([])
    const [role, setRole] = React.useState('');
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [fieldResponse, setFieldResponse] = useState('');

    useEffect(() => {
        if (password1 === password2) {
            setError2(false)
        } else {
            setError2(true)
        }
    }, [password1, password2])


    useEffect(() => {
        if (pass1 === pass2) {
            setError(false)
        } else {
            setError(true)
        }
    }, [pass1, pass2])

    const getUserList = async () => {
        const data = await client.query({
            query: GET_USERS2,
            variables: {
                locale: 'en',
                v_tenants_guid: tenantGuid,
                v_users_guid: user.user_uid
            }
        })

        setUsers(data?.data.dal_get_users_list2)
    }

    const getRoles = async () => {
        const { data } = await client.query({
            query: GET_ROLES,
            variables: { locale: 'en', v_tenants_guid: tenantGuid }
        })

        setRoles(data.dal_get_users_list)
    }

    useEffect(() => {
        getUserList()
        getRoles()
    }, [])

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    const changePass = async (passwordChange) => {
        try {
            const d = await axios.post(`${URL}/admin/user/password`, {
                user_uid: targetRow.usersGuid,
                password: passwordChange,
                locale: 'en',
            }, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`
                }
            })
            if (d.status === 200) {
                showToast('Password has been updated', 'success')
                getUserList();
            }
        }

        catch (e) {
            showToast(`An error occurred: ${e.message} ${e.response ? `(${e.response.data.error})` : null}`, 'danger')
            console.error('An error occurred:', e);
        }

    }

    const handleClickDisable = async (row) => {
        try {
            const d = await axios.post(`${URL}/admin/user/disable`, {
                user_uid: row.usersGuid,
                role_uid: row.roleId,
                locale: 'en'
            }, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`
                }
            })
            if (d.status === 200) {
                showToast('User has been disabled', 'success')
                getUserList();
            }
        }
        catch (e) {
            showToast(`An error occurred: ${e.message} ${e.response ? `(${e.response.data.error})` : null}`, 'danger')
            console.error('An error occurred:', e);
        }

    }

    const handleClickEnable = async (row) => {
        try {
            const res = await axios.post(`${URL}/admin/user/enable`, {
                user_uid: row.usersGuid,
                role_uid: row.roleId,
                locale: 'en'
            }, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`
                }
            })
            if (res.status === 200) {
                showToast('User has been activated', 'success')
                getUserList();
            }
        }
        catch (e) {
            showToast(`An error occurred: ${e.message} ${e.response ? `(${e.response.data.error})` : null}`, 'danger')
            console.error('An error occurred:', e);
        }
    }

    const createUserHandleClick = async () => {

        if (password1 === password2) {
            setError2(false)
            try {
                const d = await axios.post(`${URL}/admin/user/create_in_tenant`, {
                    username: userName,
                    role_uid: role,
                    password: password1,
                    tenant_uid: tenantGuid,
                    user_secret: roleIsUser ? null : userSecret,
                    user_secret_use: checked,
                    locale: 'en'
                }, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`
                    }
                })
                if (d.status === 200) {
                    showToast('User has been created', 'success')
                    getUserList();
                    setModalIsOpenNewUser(false);
                }
                setModalIsOpenPassword(false)
            }

            catch (e) {
                showToast(`An error occurred: ${e.message} ${e.response ? `(${e.response.data.error})` : null}`, 'danger')
                console.error('An error occurred:', e);
            }

        } else {
            setError2(true)
        }

    }

    const editHandleClick = async () => {
        try {
            const d = await axios.post(`${URL}/admin/user/update`, {
                user_uid: targetRow.usersGuid,
                role_uid: role,
                user_secret: roleIsUser ? null : userSecret,
                user_secret_use: secretChecked,
                locale: 'en',
            }, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`
                }
            })
            if (d.status === 200) {
                showToast('User has been updated', 'success')
                getUserList();
                setModalIsOpenEdit(false);
            }
        }
        catch (e) {
            showToast(`An error occurred: ${e.message} ${e.response ? `(${e.response.data.error})` : null}`, 'danger')
            console.error('An error occurred:', e);
        }

    }

    const setPasswordHandleClick = () => {
        if (pass1 === pass2) {
            setError(false)
            changePass(pass1)
            setModalIsOpenPassword(false)
        } else {
            setError(true)
        }
        setModalIsOpen(false)
    }

    // console.log(users)

    const rows = users.map(user => ({
        name: user.user_name,
        roleName: user.role_name,
        roleId: user.roles_guid,
        usersGuid: user.users_guid,
        isDeactivated: user.is_deactivated,
        effectiveFrom: user.effective_from,
        user_secret_use: user.user_secret_use
    }))

    const getUserNameEmail = async () => {
        const data = await client.query({
            query: GET_USER_NAME_EMAIL,
            variables: {
                locale: 'en',
                v_tenants_guid: tenantGuid,
                _eq: tenantUserGuid
            }
        })
        setUserNameEmail(data?.data?.dal_get_users_list?.[0]?.user_name)
    }

    useEffect(() => {
        getUserNameEmail()
    }, [])

    const [targetRow, setTargetRow] = useState(null)
    const [checked, setChecked] = useState(targetRow?.isDeactivated || true)
    const [secretChecked, setSecretChecked] = useState(targetRow?.user_secret_use || true)

    const currentRow = (name) => {
        setTargetRow(rows.find(row => row.name === name))
    }

    const content = (
        <>
            {targetValue === 'edit-user' &&
                <EditModal secretChecked={secretChecked} role={role} setSecretChecked={setSecretChecked} setModalIsOpen={setModalIsOpenEdit} rows={rows}
                    targetRow={targetRow} setUserSecret={setUserSecret} userSecret={userSecret} setRole={setRole} roles={roles} />}
            {targetValue === 'delete-user' && <ConsentModal setModalIsOpen={setModalIsOpen} />}
            {targetValue === 'set-password' &&
                <InputModal
                    pass1={pass1}
                    pass2={pass2}
                    error={error}
                    setPass1={setPass1}
                    setPass2={setPass2}
                    targetRow={targetRow}
                    userNameEmail={userNameEmail}
                    setModalIsOpen={setModalIsOpenPassword}
                />}
            {targetValue === 'add-new-user' && (
                <CreateUser
                    error={error2}
                    password1={password1}
                    password2={password2}
                    setPassword1={setPassword1}
                    setPassword2={setPassword2}
                    role={role}
                    setRole={setRole}
                    roleIsUser={roleIsUser}
                    roles={roles}
                    setRoleIsUser={setRoleIsUser}
                    setRoles={setRoles}
                    setUserSecret={setUserSecret}
                    userSecret={userSecret}
                    userName={userName}
                    setUserName={setUserName}
                    targetRow={targetRow}
                    userNameEmail={userNameEmail}
                    setModalIsOpen={setModalIsOpen}
                />
            )}
        </>
    )

    const [modalTitle, setModalTitle] = useState('')
    useEffect(() => {
        if (targetValue === 'edit-user') {
            setModalTitle('Update user')
        } else if (targetValue === 'delete-user') {
            setModalTitle('Delete user')
        } else if (targetValue === 'set-password') {
            setModalTitle('Set password')
        } else if (targetValue === 'add-new-user') {
            setModalTitle('Add new user')
        }

    }, [targetValue])

    // const [save, setSave] = useState(() => {})
    //
    // useEffect(() => {
    //     if (targetValue === 'edit-user') {
    //         // setSave('Edit user')
    //     } else if (targetValue === 'delete-user') {
    //         // setSave('Delete user')
    //     } else if (targetValue === 'set-password') {
    //         setSave(() => setPasswordHandleClick)
    //     } else if (targetValue === 'add-new-user') {
    //         // setSave('Add new user')
    //     }
    // }, [targetValue])

    return (
        <>
            <Sidebar>
                <Box component="main" className="mapping-wrapper">
                    <ToastComponent ref={toastRef} />
                    <Toolbar />
                    <div>
                        <Header setModalIsOpen={setModalIsOpenNewUser} setTargetValue={setTargetValue} />
                        <p style={{ marginLeft: '10px', marginTop: '10px', fontWeight: 'bold' }}>Users</p>
                        <table className="table">
                            <thead className="table_head">
                                <tr role="row">
                                    <th role="columnheader" scope="col">User name</th>
                                    <th role="columnheader" scope="col">Role name</th>
                                    <th role="columnheader" scope="col">User guid</th>
                                    <th role="columnheader" scope="col">Active</th>
                                    <th role="columnheader" scope="col">Effective from</th>
                                    <th role="columnheader" scope="col"></th>
                                    <th role="columnheader" scope="col"></th>
                                </tr>
                            </thead>

                            <tbody className="table_body" role="rowgroup">

                                {rows.map((row, idx) => {
                                    const dt = new Date(row.effectiveFrom)
                                    const date = new Date(dt.toString().toLocaleString()).toLocaleString()

                                    return (
                                        <tr role="row" key={`${row.name}_${idx}`}>
                                            <td style={{ verticalAlign: 'middle' }} role="cell">{row.name}</td>
                                            <td style={{ verticalAlign: 'middle' }} role="cell">{row.roleName}</td>
                                            <td style={{ verticalAlign: 'middle' }} role="cell">{row.usersGuid}</td>
                                            <td style={{ verticalAlign: 'middle' }} role="cell">
                                                <Button variant="contained" color={row.isDeactivated ? 'error' : 'success'} onClick={() => {
                                                    row.isDeactivated ? handleClickEnable(row) : handleClickDisable(row)
                                                }}>{row.isDeactivated ? 'Disabled' : 'Active'}</Button>
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }} role="cell">{date}</td>

                                            {/*<td role="cell" >{mapTypes.find(el => el.maps_types_guid === map.maps_types_guid) ?*/}
                                            {/*    mapTypes.find(el => el.maps_types_guid === map.maps_types_guid).maps_types_default_display_name : ''}</td>*/}

                                            <td className="" role="cell">
                                                <div className="table_col__btn_wrapper">
                                                    <Button variant="contained" onClick={() => {
                                                        setModalIsOpenEdit(true)
                                                        currentRow(row.name)
                                                        setTargetValue('edit-user')
                                                    }}>Update user</Button>
                                                    <Button variant="contained" onClick={() => {
                                                        setModalIsOpenPassword(true)
                                                        currentRow(row.name)
                                                        setTargetValue('set-password')
                                                    }}>Set password</Button>
                                                    {/*<Button variant="contained" color="error"*/}
                                                    {/*        onClick={() => onMapDelete(map.maps_guid)}>Delete</Button>*/}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}

                                {rows.length === 0 &&
                                    <tr role="row">
                                        <td colSpan="4" style={{ textAlign: 'center' }}>NO USERS</td>
                                    </tr>
                                }
                            </tbody>
                        </table>


                        {/*<SynModal*/}
                        {/*    content={content}*/}
                        {/*    openModal={modalIsOpen}*/}
                        {/*    title={modalTitle}*/}
                        {/*    // save={save}*/}
                        {/*    cancel={() => setModalIsOpen(false)}*/}
                        {/*/>*/}
                        <SynModal
                            content={content}
                            openModal={modalIsOpenEdit}
                            title={modalTitle}
                            save={editHandleClick}
                            cancel={() => setModalIsOpenEdit(false)}
                        />
                        <SynModal
                            error={error}
                            content={content}
                            openModal={modalIsOpenPassword}
                            title={modalTitle}
                            save={setPasswordHandleClick}
                            cancel={() => setModalIsOpenPassword(false)}
                        />
                        <SynModal
                            error={error2}
                            content={content}
                            openModal={modalIsOpenNewUser}
                            title={modalTitle}
                            save={createUserHandleClick}
                            cancel={() => setModalIsOpenNewUser(false)}
                        />
                    </div>
                </Box>
            </Sidebar>
        </>
    );
};