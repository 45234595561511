import * as React from "react";

export const TreeviewPolygonCreateContext = React.createContext({
    clickTreeviewPolygonCreateButton: () => {},
    treeviewPolygonCreateButton: false,
});

export const TreeviewPolygonCreateContextProvider = ({ children }) => {
    const [treeviewPolygonCreateButton, setTreeviewPolygonCreateButton] = React.useState(false);
    const treeviewPolygonCreateButtonValue = React.useMemo(
        () => ({
            clickTreeviewPolygonCreateButton: (value) => {
                setTreeviewPolygonCreateButton(value);
                
            },
            treeviewPolygonCreateButton,
        }),
        [treeviewPolygonCreateButton]
    );
    


    return(
        <TreeviewPolygonCreateContext.Provider value={treeviewPolygonCreateButtonValue}>
            {children}
        </TreeviewPolygonCreateContext.Provider>
    );
};

export const useTreeviewPolygonCreate= () => React.useContext(TreeviewPolygonCreateContext);