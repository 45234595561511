import { useMap } from 'react-leaflet'
import { useApolloClient } from '@apollo/react-hooks'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastComponent } from '@syncfusion/ej2-react-notifications'

import { addMapsRegionsGroups, addRegions, addRegionsGroupsRegions, updateMapsView } from '../../apollo/mutations'
import { getTaskStatus } from '../../apollo/queries'
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations'
import { OPENSTREETMAP_TYPE_GUID, RENDERED_MAP_TYPE_GUID } from '../../../utils/data'

// wrapper for openstreet map
export const ContextMenuWrapper = ({ isOpen, point, setContextMenuShow, setExpanded, isExpanded, toastRef, activeCentroid }) => {
    let map = useMap()
    return (<ContextMenu activeCentroid={activeCentroid} toastRef={toastRef} map={map} mapImage={null} isOpen={isOpen} point={point} setContextMenuShow={setContextMenuShow}
                         setExpanded={setExpanded} isExpanded={isExpanded}/>)
}

// uses in rendered map and in ContextMenuWrapper
export const ContextMenu = ({ activeCentroid, map, mapImage, isOpen, point, setContextMenuShow, setExpanded, isExpanded, toastRef }) => {
    const client = useApolloClient()
    let savingContextMenuRef = useRef()
    const dispatch = useDispatch()

    const currentRegionGroup = useSelector((state) => state.mainReducer.currentRegionGroup)
    const unsavedRegionsLength = useSelector((state) => state.mainReducer.unsavedRegionsLength)
    const currentMap = useSelector((state) => state.mainReducer.currentMap)

    const locale = localStorage.getItem('locale')

    useEffect(() => {
        if (isOpen && savingContextMenuRef?.current) savingContextMenuRef.current.open(point.y, point.x)
    }, [isOpen, savingContextMenuRef])

    useEffect(() => {
        if (savingContextMenuRef?.current) {
            if (unsavedRegionsLength > 0) savingContextMenuRef.current.enableItems(['Save Regions to Group'], true)
            else savingContextMenuRef.current.enableItems(['Save Regions to Group'], false)
        }
    }, [unsavedRegionsLength, savingContextMenuRef])

    useEffect(() => {
        if (savingContextMenuRef?.current) {
            if (currentRegionGroup && currentMap.maps_guid && unsavedRegionsLength > 0) {
                savingContextMenuRef.current.enableItems(['Save and Allocate to Map'], true)
            } else savingContextMenuRef.current.enableItems(['Save and Allocate to Map'], false)
        }
    }, [currentRegionGroup, currentMap.maps_guid])

    // from px to sm
    let convertRegionCoordinates = (type, value) => {
        return type === 'x'
            ? Math.round(currentMap.maps_real_size.roomW * value / mapImage.width) //mapImage.width * value / currentMap.maps_real_size.roomW
            : Math.round(currentMap.maps_real_size.roomH * value / mapImage.height) // mapImage.height * value / currentMap.maps_real_size.roomH;
    }

    let onMapSave = async () => {
        // save regions and allocate to map
        //save region group on exact map

        if (!currentRegionGroup) {
            showToast('Please select the region group before saving regions', 'danger')
            return
        }

        if (!currentMap.maps_guid) {
            showToast('Please select map before saving ', 'danger')
            return
        }
        onRegionGroupSave()
        // update maps view at first
        const { data } = await client.mutate({
            mutation: updateMapsView,
            variables: {
                maps_views_guid: null,
                maps_active_centroid: activeCentroid,
                maps_views_name: currentMap.maps_name,
                // maps_views_centroid: currentMap.maps_types_guid === 'a51868b4-04fe-4cd7-aefd-c14cc0672378' ? [mapImage.height / 2, mapImage.width / 2] : 'point (' + map.getCenter().lng + ' ' + map.getCenter().lat +')',
                maps_views_centroid: 'point (' + map.getCenter().lng + ' ' + map.getCenter().lat + ')',
                maps_views_zoom: Math.round(Number(map.getZoom())),
                maps_guid: currentMap.maps_guid,
                locale: locale ? locale : 'en'
            }
        })

        if (data?.insert_tasks_tasks?.returning) {
            const status = await client.query({
                query: getTaskStatus,
                variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid }
            })
            if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                await onLinkMapWithRegionGroup()
                showToast('Map was updated', 'success')

            } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                showToast('Something went wrong!', 'danger')
            }
        }
    }

    const onLinkMapWithRegionGroup = async () => {
        const { data } = await client.mutate({
            mutation: addMapsRegionsGroups,
            variables: {
                maps_regions_groups_guid: null,
                maps_guid: currentMap.maps_guid,
                regions_groups_guid: currentRegionGroup,
                locale: locale ? locale : 'en'
            }
        })

        if (data?.insert_tasks_tasks?.returning) {
            const status = await client.query({
                query: getTaskStatus,
                variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid }
            })
            if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                showToast('Region group was associated with map', 'success')
                if (unsavedRegionsLength > 0) onRegionGroupSave()
            }
            // else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
            //     showToast('Something went wrong!', 'danger')
            // }
        }
        setContextMenuShow(prevState => ({ ...prevState, value: false, point: { x: null, y: null } }))
    }


    let onRegionGroupSave = () => {
        //save polygons in exact district
        map.pm.getGeomanLayers().forEach(el => {
            if (el.properties?.local_id && !el.properties?.regions_guid) saveRegions(el)
        })

        setContextMenuShow(prevState => ({ ...prevState, value: false, point: { x: null, y: null } }))
    }

    let saveRegions = async (el) => {
        // save regions to group
        if (!currentRegionGroup) {
            showToast('Please select the region group before saving regions', 'error')
            return
        }

        let geometryCoordinates = el.toGeoJSON().geometry.coordinates

        let regions_definition = 'POLYGON (('
        // let regions_definition = region.properties.type.toUpperCase() + ' ((';

        console.log(geometryCoordinates)


        if (currentMap.maps_types_guid === RENDERED_MAP_TYPE_GUID) {
            geometryCoordinates.forEach(el => {
                el.forEach(coord => regions_definition += convertRegionCoordinates('x', coord[0]) + ' ' + convertRegionCoordinates('y', coord[1]) + ', ')

                regions_definition = regions_definition.slice(0, -2)
                regions_definition += '), ('
            })

            regions_definition = regions_definition.slice(0, -3)
            regions_definition += ')'
        } else if (currentMap.maps_types_guid === OPENSTREETMAP_TYPE_GUID) {
            geometryCoordinates.forEach(el => {
                el.forEach(coord => regions_definition += coord[0] + ' ' + coord[1] + ', ')

                regions_definition = regions_definition.slice(0, -2)
                regions_definition += '), ('
            })

            regions_definition = regions_definition.slice(0, -3)
            regions_definition += ')'
        }

        console.log(regions_definition)


        // if(el.properties.type === 'cut-polygon') {
        //     geometryCoordinates = el.toGeoJSON().geometry.coordinates;
        //     geometryCoordinates.forEach(el => {
        //         el.forEach(coord => regions_definition += coord[0] + ' ' +  coord[1] + ', ');
        //
        //         regions_definition = regions_definition.slice(0, -2);
        //         regions_definition += '), (';
        //     });
        //
        //     regions_definition = regions_definition.slice(0, -3);
        //     regions_definition += ')';
        // } else {
        //     geometryCoordinates = el.toGeoJSON().geometry.coordinates[0].length > 1 ? el.toGeoJSON().geometry.coordinates[0] :
        //         el.toGeoJSON().geometry.coordinates[0].length === 1 && el.toGeoJSON().geometry.coordinates[0][0].length > 1 ? el.toGeoJSON().geometry.coordinates[0][0] : [];
        //
        //     geometryCoordinates.forEach(el => regions_definition += el[0] + ' ' +  el[1] + ', ');
        //     regions_definition = regions_definition.slice(0, -2) + '))';
        // }

        const { data } = await client.mutate({
            mutation: addRegions,
            variables: {
                regions_guid: null,
                maps_active_centroid: activeCentroid,
                maps_views_zoom: Math.round(Number(map.getZoom())),
                regions_name: el.properties.regions_name,
                regions_types_guid: el.properties.regions_types_guid,
                regions_definition,
                regions_centroid: 'point (' + el.getBounds().getCenter().lng + ' ' + el.getBounds().getCenter().lat + ')',
                locale: locale ? locale : 'en'
            }
        })
        if (data?.insert_tasks_tasks?.returning) {
            const status = await client.query({
                query: getTaskStatus,
                variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid }
            })
            if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                map.pm.getGeomanLayers().find(layer => layer.properties?.local_id === el.properties?.local_id).setStyle({ color: '#3388ff' })
                dispatch({ type: 'SET_UNSAVED_REGIONS_LENGTH', data: 0 })
                if (status?.data?.dal_get_task_status[0].gql_results?.gql_results?.regions_guid) {
                    map.pm.getGeomanLayers().find(layer => layer.properties?.local_id === el.properties?.local_id).properties.regions_guid = status.data.dal_get_task_status[0].gql_results.gql_results.regions_guid
                    map.pm.getGeomanLayers().find(layer => layer.properties?.local_id === el.properties?.local_id).properties.local_id = null
                    saveRegionInRegionGroup(status.data.dal_get_task_status[0].gql_results.gql_results.regions_guid)
                } else {
                    showToast('Region was added', 'success')
                }
            } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                showToast('Something went wrong!', 'danger')
            }
        }
    }

    let saveRegionInRegionGroup = async (regions_guid) => {
        const { data } = await client.mutate({
            mutation: addRegionsGroupsRegions,
            variables: {
                regions_groups_regions_guid: null,
                regions_groups_guid: currentRegionGroup,
                regions_guid: regions_guid,
                locale: locale ? locale : 'en'
            }
        })
        if (data?.insert_tasks_tasks?.returning) {
            const status = await client.query({
                query: getTaskStatus,
                variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid }
            })
            if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
                showToast('Region was added and linked with selected group', 'success')


            } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
                showToast('Something went wrong!', 'danger')

            }
        }
    }

    let beforeClose = () => {
        setContextMenuShow(prevState => ({ ...prevState, value: false, point: { x: null, y: null } }))
    }

    let settingsPanelView = () => {
        setExpanded(!isExpanded)
    }

    const saveMapView = async () => {
        const { data } = await client.mutate({
            mutation: updateMapsView,
            variables: {
                maps_views_guid: currentMap?.maps_view?.maps_views_guid,
                maps_views_name: currentMap.maps_name,
                maps_views_centroid: 'point (' + map.getCenter().lng + ' ' + map.getCenter().lat + ')',
                maps_views_zoom: Math.round(Number(map.getZoom())),
                maps_guid: currentMap.maps_guid,
                locale: locale ? locale : 'en'
            }
        })

        const { data: dataStatus } = await client.query({
            query: getTaskStatus,
            variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid }
        })

        if (dataStatus?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
            showToast(dataStatus?.dal_get_task_status[0]?.return_code_locale?.message, 'success')
        } else {
            showToast(dataStatus?.dal_get_task_status[0].return_code_locale.message, 'danger')
        }

        //
        // const { data } = await client.mutate({
        //     mutation: addRegionsGroupsRegions,
        //     variables: {
        //         regions_groups_guid: currentRegionGroup,
        //         // regions_guid: regions_guid,
        //         regions_guid: null,
        //         maps_active_centroid: activeCentroid,
        //         maps_views_zoom: Math.round(Number(map.getZoom())),
        //         locale: locale ? locale : 'en'
        //     }
        // })
        // if (data?.insert_tasks_tasks?.returning) {
        //     const status = await client.query({
        //         query: getTaskStatus,
        //         variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid }
        //     })
        //     // if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'COMPLETED') {
        //     //     showToast('Region was added and linked with selected group', 'success')
        //     //
        //     // } else if (status?.data?.dal_get_task_status[0].gql_results?.task_status === 'FAILED') {
        //     //     showToast('Something went wrong!', 'danger')
        //     // }
        // }
    }

    let onPropertyChanged = (event) => {
        switch (event.item.id) {
            case 'drawer':
                settingsPanelView()
                break
            case 'map':
                onMapSave()
                break
            case 'district':
                onRegionGroupSave()
                break
            case 'saveMapView':
                saveMapView()
                break
            default:
                setContextMenuShow(prevState => ({ ...prevState, value: false, point: { x: null, y: null } }))
                break
        }
    }

    let polygonContextMenuItems = [
        // { id: 'drawer', text: 'Settings Panel' },
        { id: 'map', text: 'Save and Allocate to Map' },
        { id: 'district', text: 'Save Regions to Group'},
        { id: 'saveMapView', text: 'Save Map View' }
    ]

    const showToast = (message, type) => {
        toastRef?.current?.show({
            content: message,
            cssClass: `e-toast-${type}`,
            position: { X: 'Right', Y: 'Top' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    return (
        <ContextMenuComponent
            id="renderedMapContextMenu"
            ref={savingContextMenuRef}
            items={polygonContextMenuItems}
            select={onPropertyChanged}
            beforeClose={beforeClose}
        />
    )
}
