import gql from 'graphql-tag';

export const getTaskStatus = gql`
    query getTaskStatus($tasks_guid: uuid, $locale: String){
        dal_get_task_status(args: {v_locale: $locale, v_tasks_guid: $tasks_guid}) {
            gql_results
            return_code
            return_code_locale
          }
}`;

export const getDataSourcesList = gql`
  query getDataSourcesList($locale: String) {
    dal_get_data_sources_list(args: {v_locale: $locale}){
        data_sources_guid
        data_sources_name
    }
  }
`;

export const getMapTypes = gql`
  query getMapTypes($locale: String) {
    dal_get_maps_types_list(args: {v_locale: $locale}){
      maps_types_guid
      maps_types_name
      tenants_guid
    }
  }
`;

// todo
export const getMap = gql`
  query getMap($locale: String, $maps_guid: uuid, $tenants_guid: uuid, $users_guid: uuid) {
    dal_get_maps(args: {v_locale: $locale, v_maps_guid: $maps_guid, v_tenants_guid: $tenants_guid, v_users_guid: $users_guid}){
        gql_results
        return_code
        return_code_locale
    }
  }
`;

export const getMaps = gql`
  query getMaps($maps_types_guid: uuid, $locale: String) {
    dal_get_maps_list2(args: {v_maps_types_guid: $maps_types_guid, v_locale: $locale}){
        maps_guid
        maps_name
        maps_types_guid
        maps_types_name
        maps_real_size
        tenants_guid
    }
  }
`;

export const getMapsViews = gql`
  query getMapsViews($locale: String) {
    dal_get_maps_views_list(args: {v_locale: $locale}){
        maps_name
        maps_views_centroid
        maps_views_guid
        maps_views_name
        maps_views_zoom
    }
  }
`;

export const getMapsViewByMap = gql`
  query getMapsViewByMap($locale: String, $maps_guid: uuid) {
    dal_get_maps_views_list2(args: {v_maps_guid: $maps_guid, v_locale: $locale}){
        maps_name
        maps_views_centroid
        maps_views_guid
        maps_views_name
        maps_views_zoom
    }
  }
`;

//todo
// export const getInterest = gql `
//   query getInterest($interests_guid: uuid, $tenants_uid: uuid, $users_guid: uuid) {
//       dal_rtls_get_interests(args: {v_maps_views_guid: $interests_guid, v_locale: "en", v_tenants_guid: $tenants_uid, v_users_guid: $users_guid}) {
//         gql_results
//         return_code
//         return_code_locale
//       }
//   }
// `;



export const getRegionsTypes = gql`
  query getRegionsTypes($locale: String) {
      dal_get_regions_types_list(args: {v_locale: $locale}) {
        region_type_name
        regions_types_guid
      }
    }
`;

export const getRegionsTypesSettigns = gql`
  query getRegionsTypes($locale: String, $maps_guid: uuid, $tenants_guid: uuid, $users_guid: uuid) {
    dal_get_regions_types_list2(args: {v_locale: $locale, v_maps_guid: $maps_guid, v_tenants_guid: $tenants_guid, v_users_guid: $users_guid}) {
      region_type_name
      regions_types_guid
    }
  }
`

export const getRegionsGroups = gql`
  query getRegionsGroups($locale: String) {
    dal_get_regions_groups_list(args: {v_locale: $locale}){
        regions_groups_guid
        regions_groups_name 
    }
  }
`;

export const getMapRegionsGroups = gql`
  query getMapRegionsGroups($maps_guid: uuid, $locale: String) {
    dal_get_regions_groups_list2(args: {v_maps_guid: $maps_guid, v_locale: $locale}){
        regions_groups_guid
        regions_groups_name
    }
  }
`;

export const getRegionsGroupsByMapType = gql`
  query getRegionsGroupsByMapType($maps_types_guid: uuid, $locale: String) {
    dal_get_regions_groups_list3(args: {v_maps_types_guid: $maps_types_guid, v_locale: $locale}){
        maps_types_name
        regions_groups_guid
        regions_groups_name
    }
  }
`;


// free regions that doesn't linked with map
export const getUnallocatedRegionsGroups = gql`
  query getUnallocatedRegionsGroups($maps_guid: uuid, $locale: String) {
    dal_get_regions_groups_unallocated_list2(args: {v_maps_guid: $maps_guid, v_locale: $locale}){
        regions_groups_guid
        regions_groups_name
    }
  }
`;

export const getRegions = gql`
  query getRegions($locale: String, $regions_groups_guid: uuid, $regions_types_guid: uuid) {
    dal_get_regions_list2(args: {v_locale: $locale, v_regions_groups_guid: $regions_groups_guid, v_regions_types_guid: $regions_types_guid}){
        regions_groups_guid
        regions_guid
        regions_name
        regions_types_guid
        regions_types_name
        regions_definition
        regions_centroid
    }
  }
`;

export const getRegionsFromGroup = gql`
    query MyQuery($locale: String, $v_regions_groups_guid: uuid) {
      dal_get_regions_list2(args: {v_locale: $locale, v_regions_groups_guid: $v_regions_groups_guid, v_regions_types_guid: null}) {
        regions_centroid
        regions_definition
        regions_groups_guid
        regions_groups_name
        regions_guid
        regions_name
        regions_types_guid
        regions_types_name
        tenants_guid
      }
    }
`

export const getMapRegions = gql`
  query getMapRegions($locale: String, $maps_guid: uuid, $tenants_guid: uuid) {
    dal_get_regions_list3(args: {v_locale: $locale, v_maps_guid: $maps_guid, v_tenants_guid: $tenants_guid}){
        regions_groups_guid
        regions_guid
        regions_name
        regions_types_guid
        regions_types_name
        regions_definition
    }
  }
`;

export const editRegions = gql`
  query getRegionsGroups($locale: String, $regions_guid: uuid) {
    dal_edit_regions(args: {v_locale: $locale, v_regions_guid: $regions_guid}){
        regions_guid
        regions_name
        regions_types_guid
    }
  }
`;