import { createRoot } from 'react-dom/client';
import App from "./App";
import { registerLicense } from '@syncfusion/ej2-base'
import {} from "./arjs-license";
import { Provider } from 'react-redux';
import { store } from './store';
import "./styles/index.scss";

registerLicense('ORg4AjUWIQA/Gnt2VFhiQlJPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXtQdkVlWXlfdHJQRWk=');

createRoot(document.getElementById('root')).render(
    <Provider store={store}>
        <App />
    </Provider>   
);
