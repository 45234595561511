import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DropDown } from '../CreateUser/DropDown';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const EditModal = ({ role, targetRow, roles, setRole, setUserSecret, userSecret, setSecretChecked, secretChecked, accountPage }) => {
    const onChangeCheckbox = (event) => {
        setSecretChecked(event.target.checked);
    }

    useEffect(() => {
        setSecretChecked(targetRow.user_secret_use)
        return () => {
            setSecretChecked(false);
            setUserSecret(userSecret);
            setRole('');
        };
    }, [])

    return (
        <Box>
            <div style={{ marginBottom: 32, width: 200 }}>
                <DropDown roles={roles} role={role} setRole={setRole} targetRow={targetRow} accountPage={accountPage}/>
            </div>
            <div style={{ marginBottom: 32, width: 200, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <FormControlLabel control={<Checkbox
                    checked={secretChecked}
                    onChange={onChangeCheckbox} 
                    value={targetRow.user_secret_use}
                    />} label="User secret use"
                />
                <TextField
                    onChange={(event) => {
                        setUserSecret(event.target.value)
                    }}
                    disabled={!secretChecked}
                    value={userSecret}
                    id="outlined-password-input"
                    // label="Password"
                    type="number"
                // autoComplete="current-password"
                />
            </div>
            {/*<div style={{ marginTop: 12 }}>*/}
            {/*    /!*<Button onClick={() => setModalIsOpen(false)}>Close modal</Button>*!/*/}
            {/*    <Button onClick={applyHandleClick}>Apply</Button>*/}
            {/*</div>*/}
        </Box>
    );
};