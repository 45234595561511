import { useState, useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_POLYGONS_FOR_MAP_LIST } from '../../../../apollo/queries';

export const useFetchPolygons = (panel, user, isTreeViewToggled) => {
    const client = useApolloClient();
    const [polygonsForMapList, setPolygonsForMapList] = useState([]);

    const fetchPolygonsForMapList = useCallback(async () => {
        try {
            const { data } = await client.query({
                query: GET_POLYGONS_FOR_MAP_LIST,
                variables: {
                    locale: 'en',
                    v_sys_panels_maps_guid: panel.sys_panels_guid,
                    v_tenants_guid: user.tenant.tenants_guid
                }
            });
            setPolygonsForMapList(data);
        } catch (error) {
            console.error('Error fetching polygons:', error);
        }
    }, [client, panel.sys_panels_guid, user.tenant.tenants_guid]);

    useEffect(() => {
        fetchPolygonsForMapList();
    }, [fetchPolygonsForMapList, isTreeViewToggled]);

    return polygonsForMapList;
};
