import * as React from "react";

export const TreeviewSavedContext = React.createContext({
    clickTreeviewSaveButton: () => {},
    treeviewSaveButton: false,
});

export const TreeviewSavedContextProvider = ({ children }) => {
    const [treeviewSaveButton, setTreeviewSaveButton] = React.useState(false);
    const treeviewSaveButtonValue = React.useMemo(
        () => ({
            clickTreeviewSaveButton: (value) => {
                setTreeviewSaveButton(value);
                
            },
            treeviewSaveButton,
        }),
        [treeviewSaveButton]
    );
    


    return(
        <TreeviewSavedContext.Provider value={treeviewSaveButtonValue}>
            {children}
        </TreeviewSavedContext.Provider>
    );
};

export const useTreeviewSaved= () => React.useContext(TreeviewSavedContext);