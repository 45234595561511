import React from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'

const SynModal = ({ cancel, openModal, save, content, title, loading, shouldUpdate = true, error = false }) => (
    <Dialog
        maxWidth="md"
        onClose={cancel}
        aria-labelledby="customized-dialog-title"
        open={openModal}
    >
        <DialogTitle id="customized-dialog-title">
            {title}
            <IconButton
                aria-label="close"
                onClick={cancel}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8
                }}
            >
                <CloseIcon/>
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
            <Button autoFocus disabled={loading || !shouldUpdate || error} onClick={save}>Save</Button>
            <Button onClick={cancel} disabled={loading}>Close</Button>
        </DialogActions>
    </Dialog>
)
export default SynModal
