import * as React from "react";

export const TreeviewDialogContext = React.createContext({
    openTreeviewDialog: () => {},
    dialogIsOpen: false,
});

export const TreeviewDialogContextProvider = ({ children }) => {
    const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
    const treeviewDialogBox = React.useMemo(
        () => ({
            openTreeviewDialog: (value) => {
                setDialogIsOpen(value);
                
            },
            dialogIsOpen,
        }),
        [dialogIsOpen]
    );
    


    return(
        <TreeviewDialogContext.Provider value={treeviewDialogBox}>
            {children}
        </TreeviewDialogContext.Provider>
    );
};

export const useTreeViewDialog = () => React.useContext(TreeviewDialogContext);