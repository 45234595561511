import gql from "graphql-tag";

export const createPet = gql `
    mutation createPet($task_name: String, $tasks_types_guid: uuid, $animals_genders_guid: uuid, $birth_date: timestamp, $display_name: String, $cats_breeds_guid: uuid, $cats_colours_guid: uuid){
        insert_tasks_tasks(objects: {
            task_name: $task_name, 
            task_params:
            [
                {
                    param_type:"uuid",
                    param:"v_animals_genders_guid",
                    param_value:$animals_genders_guid,
                }, 
                {
                    param_type:"timestamp without time zone",
                    param:"v_birth_date",
                    param_value:$birth_date,
                }, 
                {
                    param_type:"timestamp without time zone",
                    param:"v_death_date",
                    param_value:"null",
                }, 
                {
                    param_type:"character varying",
                    param:"v_entities_display_name",
                    param_value:$display_name,
                }, 
                {
                    param_type:"uuid",
                    param:"v_cats_breeds_guid",
                    param_value:$cats_breeds_guid,
                }, 
                {
                    param_type:"uuid",
                    param:"v_cats_colours_guid",
                    param_value:$cats_colours_guid,
                }, 
                {
                    param_type:"character varying",
                    param:"v_locale",
                    param_value:"en",
                }
            ],
            tasks_types_guid: $tasks_types_guid}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const updateDog = gql `
    mutation updateDog($task_name: String, $entites_uid: uuid, $tasks_types_guid: uuid, $animals_genders_guid: uuid, $birth_date: timestamp, $death_date: timestamp, $display_name: String, $dogs_breeds_guid: uuid, $dogs_colours_guid: uuid){
        insert_tasks_tasks(objects: {
            task_name: "update_dog", 
            task_params:
            [
                {
                  param: "v_entities_guid",
                  param_type: "uuid",
                  param_value: $entites_uid,
                },
                {
                    param_type:"uuid",
                    param:"v_animals_genders_guid",
                    param_value:$animals_genders_guid,
                }, 
                {
                    param_type:"timestamp without time zone",
                    param:"v_birth_date",
                    param_value:$birth_date,
                }, 
                {
                    param_type:"timestamp without time zone",
                    param:"v_death_date",
                    param_value: $death_date,
                }, 
                {
                    param_type:"character varying",
                    param:"v_entities_display_name",
                    param_value:$display_name,
                }, 
                {
                    param_type:"uuid",
                    param:"v_dogs_breeds_guid",
                    param_value:$dogs_breeds_guid,
                }, 
                {
                    param_type:"uuid",
                    param:"v_dogs_colours_guid",
                    param_value:$dogs_colours_guid,
                }, 
                {
                    param_type:"character varying",
                    param:"v_locale",
                    param_value:"en",
                }
            ],
            tasks_types_guid: "6f93e1fa-e60e-44e5-b30a-f656c9b32c1a"}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const updatePet = gql `
    mutation updatePet($task_name: String, $entites_uid: uuid, $tasks_types_guid: uuid, $animals_genders_guid: uuid, $birth_date: timestamp, $display_name: String, $cats_breeds_guid: uuid, $cats_colours_guid: uuid){
        insert_tasks_tasks(objects: {
            task_name: $task_name, 
            task_params:
            [
                {
                    param: "v_entities_guid",
                    param_type: "uuid",
                    param_value: $entites_uid,
                  },
                {
                    param_type:"uuid",
                    param:"v_animals_genders_guid",
                    param_value:$animals_genders_guid,
                }, 
                {
                    param_type:"timestamp without time zone",
                    param:"v_birth_date",
                    param_value:$birth_date,
                }, 
                {
                    param_type:"timestamp without time zone",
                    param:"v_death_date",
                    param_value:null,
                }, 
                {
                    param_type:"character varying",
                    param:"v_entities_display_name",
                    param_value:$display_name,
                }, 
                {
                    param_type:"uuid",
                    param:"v_cats_breeds_guid",
                    param_value:$cats_breeds_guid,
                }, 
                {
                    param_type:"uuid",
                    param:"v_cats_colours_guid",
                    param_value:$cats_colours_guid,
                }, 
                {
                    param_type:"character varying",
                    param:"v_locale",
                    param_value:"en",
                }
            ],
            tasks_types_guid: $tasks_types_guid}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;


export const addUsersScreen = gql `
    mutation addUsersScreen( $screen_name: String, $screen_description: String, $screen_caption: String, $locale: String){
        insert_tasks_tasks(objects: {
            task_name: "add_users_screen", 
            task_params:
            [
                {
                  param: "v_sys_apps_types_guid",
                  param_type: "uuid",
                  param_value: "5959df7f-5990-4d7b-9f33-797ed166c687"
                },
                {
                  param: "v_users_screens_guid",
                  param_type: "uuid",
                  param_value: null
                },
                {
                  param: "v_screen_name",
                  param_type: "character varying",
                  param_value: $screen_name
                },
                {
                  param: "v_screen_description",
                  param_type: "character varying",
                  param_value: $screen_description
                },
                {
                  param: "v_screen_caption",
                  param_type: "character varying",
                  param_value: $screen_caption
                },
                {
                  param: "v_screen_json",
                  param_type: "json",
                  param_value: null
                },
                {
                  param: "v_locale",
                  param_type: "character varying",
                  param_value: $locale
                }
              ],
            tasks_types_guid: "6a0c2b10-3d76-499c-bb00-825efbe88cd8"}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;


export const addUsersScreenLayout = gql `
    mutation addUsersScreenLayout($locale: String, $layout: JSON, $screen_uid: uuid){
        insert_tasks_tasks(objects: {
            task_name: "add_users_screen", 
            task_params:
            [
                {
                  param: "v_sys_apps_types_guid",
                  param_type: "uuid",
                  param_value: "5959df7f-5990-4d7b-9f33-797ed166c687"
                },
                {
                  param: "v_users_screens_guid",
                  param_type: "uuid",
                  param_value: $screen_uid
                },
                {
                  param: "v_screen_name",
                  param_type: "character varying",
                  param_value: ""
                },
                {
                  param: "v_screen_description",
                  param_type: "character varying",
                  param_value: ""
                },
                {
                  param: "v_screen_caption",
                  param_type: "character varying",
                  param_value: ""
                },
                {
                  param: "v_screen_json",
                  param_type: "json",
                  param_value: null
                },
                {
                  param: "v_locale",
                  param_type: "character varying",
                  param_value: $locale
                }
              ],
            tasks_types_guid: "6a0c2b10-3d76-499c-bb00-825efbe88cd8"}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const setUsersScreenLayout = gql `
    mutation setUsersScreenLayout($locale: String, $layout: JSON, $screen_uid: uuid){
        insert_tasks_tasks(objects: {
            task_name: "add_users_screen", 
            task_params:
            [
                {
                  param: "v_sys_apps_types_guid",
                  param_type: "uuid",
                  param_value: "5959df7f-5990-4d7b-9f33-797ed166c687"
                },
                {
                  param: "v_users_screens_guid",
                  param_type: "uuid",
                  param_value: $screen_uid
                },
                {
                  param: "v_screen_name",
                  param_type: "character varying",
                  param_value: ""
                },
                {
                  param: "v_screen_description",
                  param_type: "character varying",
                  param_value: ""
                },
                {
                  param: "v_screen_caption",
                  param_type: "character varying",
                  param_value: ""
                },
                {
                  param: "v_screen_json",
                  param_type: "json",
                  param_value: $layout
                },
                {
                  param: "v_locale",
                  param_type: "character varying",
                  param_value: $locale
                }
              ],
            tasks_types_guid: "a474d56e-73bd-4b91-9f96-3aad976d7953"}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const addMenus = gql `
    mutation addMenus($locale: String, $menu_description: String, $menu_name: String, $app_type: uuid, $menu_uid: uuid, $tenant_type: uuid){
      add_sys_menus(args: {v_locale: "en", v_menu_description: $menu_description, v_menu_name: $menu_name, v_sys_apps_types_guid: $app_type, v_sys_menus_guid: $menu_uid, v_tenants_types_guid: $tenant_type}) {
        return_code
        gql_results
      }
}`;

export const addMenuItems = gql `
    mutation addMenuItems($locale: String, $menu_description: String, $menu_name: String, $menu_item_position: Int, $menu_level: Int, $parents_menus_items_uid: uuid, $menu_uid: uuid){
      add_sys_menus_items(args: {v_locale: $locale, v_menu_description: $menu_description, v_menu_item_position: $menu_item_position, v_menu_level: $menu_level, v_menu_name: $menu_name, v_parents_menus_items_guid: $parents_menus_items_uid, v_sys_menus_guid: $menu_uid, v_sys_menus_items_guid: null, v_sys_screens_guid: null}) {
        gql_results
        return_code
        return_code_locale
      }
}`;

export const delMenus = gql `
    mutation delMenus($locale: String, $apps_type: uuid, $menu_uid: uuid, $tenants_type: uuid){
      del_sys_menus(args: {v_locale: "en", v_sys_apps_types_guid: $apps_type, v_sys_menus_guid: $menu_uid, v_tenants_types_guid: $tenants_type}) {
        gql_results
        return_code
        return_code_locale
      }
}`;

export const addProjectView = gql `
    mutation addProjectView( $projects_views_uid: uuid, $projects_uid: uuid, $lat: String, $long: String, $default_magnification: Int, $projects_views_name: String, $locale: String){
      insert_tasks_tasks(objects: {
          task_name: "set_project_views_data",
          task_params: [
            {
              param: "v_projects_views_guid",
              param_type: "uuid",
              param_value: $projects_views_uid
            },
            {
              param: "v_projects_guid",
              param_type: "uuid",
              param_value: $projects_uid
            },
            {
              param: "v_latitude",
              param_type: "numeric",
              param_value: $lat
            },
            {
              param: "v_longitude",
              param_type: "numeric",
              param_value: $long
            },
            {
              param: "v_default_magnification",
              param_type: "integer",
              param_value: $default_magnification
            },
            {
              param: "v_projects_views_name",
              param_type: "character varying",
              param_value: $projects_views_name
            },
            {
              param: "v_locale",
              param_type: "character varying",
              param_value: $locale
            },
          ]
            tasks_types_guid: "d4b15053-1d75-454b-9062-bc38ff828e3f"}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const setUserSettings = gql `
    mutation setUserSettings( $users_setting: String, $users_setting_value: String, $users_setting_description: String){
      insert_tasks_tasks(objects: {
          task_name: "set_user_setting",
          task_params: [
            {
              param: "v_sys_apps_types_guid",
              param_type: "uuid",
              param_value: "5959df7f-5990-4d7b-9f33-797ed166c687"
            },
            {
              param: "v_users_setting",
              param_type: "character varying",
              param_value: $users_setting
            },
            {
              param: "v_users_setting_value",
              param_type: "character varying",
              param_value: $users_setting_value
            },
            {
              param: "v_users_setting_description",
              param_type: "character varying",
              param_value: $users_setting_description
            },
          ]
            tasks_types_guid: "0f595c34-e49d-4d9c-b951-85624809dbb3"}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const addPersons = gql `
    mutation addPersons( $persons_gender_uid: uuid, $birth_date: timestamp, $entities_display_name: String, $locale: String){
      insert_tasks_tasks(objects: {
          task_name: "add_persons",
          task_params: [
            {
              param: "v_persons_genders_guid",
              param_type: "uuid",
              param_value: $persons_gender_uid
            },
            {
              param: "v_birth_date",
              param_type: "timestamp without time zone",
              param_value: $birth_date
            },
            {
              param: "v_death_date",
              param_type: "timestamp without time zone",
              param_value: null
            },
            {
              param: "v_note",
              param_type: "character varying",
              param_value: ""
            },
            {
              param: "v_entities_display_name",
              param_type: "character varying",
              param_value: $entities_display_name
            },
            {
              param: "v_locale",
              param_type: "character varying",
              param_value: $locale
            },
          ]
            tasks_types_guid: "0d2e5315-b912-49e0-819c-22489c6900ff"}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const addUIElements = gql `
    mutation addUIElements( $locale: String, $ui_elements_definition: String, $ui_elements_description: String, $ui_elements_name: String, $ui_elements_parameters: String, $ui_elements_query: String, $ui_elements_types_uid: uuid){
      insert_tasks_tasks(objects: {
          task_name: "add_ui_elements",
          task_params: [
            {
              param: "v_ui_elements_name",
              param_type: "character varying",
              param_value: $ui_elements_name
            },
            {
              param: "v_ui_elements_description",
              param_type: "character varying",
              param_value: $ui_elements_description
            },
            {
              param: "v_ui_elements_types_guid",
              param_type: "uuid",
              param_value: $ui_elements_types_uid
            },
            {
              param: "v_ui_elements_definition",
              param_type: "text",
              param_value: $ui_elements_definition
            },
            {
              param: "v_ui_elements_query",
              param_type: "character varying",
              param_value: $ui_elements_query
            },
            {
              param: "v_ui_elements_parameters",
              param_type: "character varying",
              param_value: $ui_elements_parameters
            },
            {
              param: "v_locale",
              param_type: "character varying",
              param_value: $locale
            },
          ]
            tasks_types_guid: "be454515-0713-4a4e-abd9-b9d1ee95ae8d"}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const updatePanelsDefinition = gql `
    mutation updatePanelsDefinition( $locale: String, $panels_definition: json, $pnaels_uid: uuid){
      insert_tasks_tasks(objects: {
          task_name: "update_sys_panels_definition",
          task_params: [
            {
              param: "v_sys_panels_guid",
              param_type: "uuid",
              param_value: $pnaels_uid
            },
            {
              param: "v_sys_panels_definition",
              param_type: "json",
              param_value: $panels_definition
            },
            {
              param: "v_locale",
              param_type: "character varying",
              param_value: $locale
            },
          ]
            tasks_types_guid: "85154ffa-16da-4872-88e5-262ccf9be25a"}) {
                affected_rows
                returning {
                    tasks_guid
                  }
          }
}`;

export const downloadFileImage = gql `
    mutation downloadFileImage($files_uid: String!) {
      fileDownload(files_guid: $files_uid, is_thumb: true) {
        file_content
      }
    }
`;
