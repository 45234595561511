import React, { useState, useEffect, useContext } from 'react';
import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import { useApolloClient } from "@apollo/react-hooks";
import Sidebar from '../../components/Sidebar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Designer } from "@grapecity/activereports-react";
import { updatePanelsDefinition } from '../../apollo/mutations';
import { useNavigate, useParams } from 'react-router-dom'
import { getDynamicScreen, getTaskStatus } from "../../apollo/queries";
import { useLocation } from 'react-router-dom';
import { GlobalTheme } from '../../context/GlobalTheme';



const Report = () => {
    const toastRef = React.useRef(null);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user'));
    const locale = localStorage.getItem('locale');
    const [panel, setPanel] = useState();
    const client = useApolloClient();
    const designerRef = React.useRef();
    const viewerRef = React.useRef();
    const [viewMode, setViewMode] = React.useState(false);
    const { state } = useLocation();


    const getReportData = (reportData) => {
        console.log(reportData)
        const report = JSON.parse(JSON.stringify(reportData.report_definition))
        // let reportQuery = reportData.panel_json.query.replace('@v_locale', 'en')
        // reportQuery = reportQuery.substring(1, reportQuery.length - 1)


        // const data = await client.query({query: gql`
        //   ${reportQuery}
        // `});
        // report.DataSources[0].ConnectionProperties.ConnectString =
        // "jsondata=" + JSON.stringify(data); 
        designerRef.current.setReport({ definition: report });

    }


    const onSave = async (info) => {
        console.log(info)
        //eslint-disable-next-line
        const { data } = await client.mutate({
            mutation: updatePanelsDefinition,
            variables: {
                locale: "en",
                panels_definition: JSON.parse(JSON.stringify(info.panel_json.report_definition)),
                pnaels_uid: info.sys_panels_guid
            }
        })

    };

    const _getDynamicScreen = async () => {
        setPanel('')
        // const { data } = await client.query({ query: getDynamicScreen, variables: { locale: locale, screens_uid: state.screenId, tenants_uid: user.tenant.tenants_guid } })
        // setPanel(data.dal_get_sys_apps_screens[0].gql_results[0].panels[0])
        // getReportData(data.dal_get_sys_apps_screens[0].gql_results[0].panels[0])
        getReportData(state.panelJSON)
    }

    useEffect(() => {
        _getDynamicScreen()
    }, [state.panelJSON])

    const showToast = (message, type) => {
        toastRef.current.show({
            content: message,
            cssClass: `e-toast-${type} custom-toast-position`,
            position: { X: 'Right', YOffset: '1000' },
            animation: { show: { duration: 500 }, hide: { duration: 500 } },
            timeOut: 3000,
        });
    }

    const onSaveAs = async function (info) {
        const { data } = await client.mutate({
            mutation: updatePanelsDefinition,
            variables: {
                locale: "en",
                panels_definition: JSON.parse(JSON.stringify(info.definition)),
                pnaels_uid: panel.sys_panels_guid
            }
        })
        if (data?.insert_tasks_tasks?.returning) {
            const status = await client.query({ query: getTaskStatus, variables: { tasks_guid: data?.insert_tasks_tasks?.returning[0].tasks_guid } })
            if (status?.data?.dal_get_task_status[0].return_code === 'SUCSS') {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'success');
                return setTimeout(() => { navigate(-1) }, 1000)
            }
            else {
                showToast(status?.data?.dal_get_task_status[0].return_code_locale.message, 'danger');
            }
        }
    };

    const globalTheme = useContext(GlobalTheme)

    return (
        <>
            <Sidebar>
                <Box
                    component="main"
                    className="main-wrapper"
                    sx={{ background: globalTheme[globalTheme.activeTheme]?.background }}
                    style={{ overflow: 'auto' }}
                >
                    <Toolbar />
                    <Container maxWidth="false">
                        <div className="col-lg-12 control-section" id="control_dash" >
                            <ToastComponent ref={toastRef} />
                            <div className="content-wrapper" style={{ "maxWidth": "100%" }}>
                                <div style={{ background: '#bbd2ec', border: '1px solid #bbd2ec', margin: '12px 16px 16px 24px' }}>
                                    <h5 style={{ padding: '11px 18px', fontSize: '14px', fontWeight: '700', margin: 0, background: '#bbd2ec', color: '#231f1f' }}>{panel && panel.panel_name}</h5>
                                    <div id="designer-host">
                                        <Designer ref={designerRef} onSave={onSave} onSaveAs={onSaveAs} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Box>
            </Sidebar>
        </>
    );
}

export default Report