import React from 'react';
import { ModalContainer } from '../styled';
import Box from '@mui/material/Box';
import { Button } from '../Header/styled';

export const ConsentModal = ({ setModalIsOpen }) => {

    return (
        <Box>
            <h2 id="child-modal-title">Text in a child modal</h2>
            <p id="child-modal-description">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            </p>
            {/*<Button onClick={() => setModalIsOpen(false)}>Close modal</Button>*/}
        </Box>
    );
};