import React, { useEffect, useState, useContext} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/react-hooks';
import { gql } from "@apollo/client";
import _ from 'lodash';
import Map from './components/Map';
import { SidebarContext } from '../Main/context';

const MapPanel = ({ panel, panelJSON, mapsQuery, queryName }) => {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const sidebarContext = useContext(SidebarContext);
    const [isLoading, setIsLoading] = useState(false);
    const [animalsData, setAnimalsData] = useState(null);
    const [controls, setControls] = useState([]);
    const refreshedPanelsGuid = useSelector(state => state.mainReducer.refreshedPanelsGuid);
    const isRefreshButtonClicked = useSelector(state => state.mainReducer.isRefreshButtonClicked);

    const _getAnimals = async () => {
        // const panelQuery = panelJSON.query;
        // const panelQueryReplaced = panelQuery.replace('@v_locale', '"en"').slice(1, -1);

        if (mapsQuery) {
            setIsLoading(true);
            try {
                const { data } = await client.query({ query: gql`${mapsQuery}` });
                setControls(panelJSON.controls || []);
                setAnimalsData(data?.[panelJSON.query_name] || []);
            } catch (error) {
                console.error('Error fetching animals data:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        if (panel.sys_panels_guid === refreshedPanelsGuid) {
            _getAnimals();
        }
        dispatch({ type: 'GET_REGIONS_GROUPS_GUID_VALUE', payload: panelJSON.map_definition.regions_groups_guid });
    }, [isRefreshButtonClicked]);

    useEffect(() => {
        _getAnimals();
        dispatch({ type: 'GET_REGIONS_GROUPS_GUID_VALUE', payload: panelJSON.map_definition.regions_groups_guid });
    }, [panelJSON, sidebarContext?.onSubmit]);


    return (
        <div style={{ overflow: 'auto', height: 'calc(100% - 41px)' }}>
            <Map panel={panel} panelJSON={panelJSON} animalsData={animalsData} controls={controls} />
        </div>
    );
};

export default MapPanel;
