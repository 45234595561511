import React, { useRef, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import MapDialogContent from './MapDialogContent';

const MapDialogComponent = () => {

    const dispatch = useDispatch();

    const isMapDialogOpen = useSelector(state => state.treeViewReducer.isMapDialogOpen);

    const closeMapDialog = () => {
        dispatch({ type: 'TOGGLE_MAP_DIALOG', payload: false })
        dispatch({ type: 'POLYGON_DRAW_PROCESS', payload: false })
    }

    const dialogContent = () => {
        return (
            <MapDialogContent />
        )
    }

    return (
        <DialogComponent
            visible={isMapDialogOpen}
            isModal={true}
            header="Polygon Dialog"
            showCloseIcon={true}
            width="250px"
            content={dialogContent}
            close={closeMapDialog}
        >
        </DialogComponent>
    )
}

export default MapDialogComponent;