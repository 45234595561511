import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

import { URL } from '../constans';
import { Button } from '../Header/styled';
import { DropDown } from './DropDown';
import axios from 'axios';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_ROLES } from '../../../apollo/queries';
import InputLabel from '@mui/material/InputLabel';

export const CreateUser = ({
                               userName,
                               setUserName,
                               userSecret,
                               setUserSecret,
                               roleIsUser,
                               setRoleIsUser,
                               roles,
                               setRoles,
                               role,
                               setRole,
                               password1,
                               password2,
                               error,
                               setPassword1,
                               setPassword2,
                           }) => {
    const user = JSON.parse(localStorage.getItem('user'))
    const [userUid, tenants_guid] = [user.user_uid, user.tenant.tenants_guid]
    const client = useApolloClient();
    const [checked, setChecked] = useState(false)
    // const [password, setPassword] = useState('')
    // const [userName, setUserName] = useState('')
    // const [userSecret, setUserSecret] = useState('1234')
    // const [roleIsUser, setRoleIsUser] = useState(false)

    useEffect(() => {
        setChecked(roleIsUser && false)
    }, [role])

    const applyHandleClick = async () => {
        const d = await axios.post(`${URL}/admin/user/create_in_tenant`, {
            username: userName,
            role_uid: role,
            password1,
            tenant_uid: tenants_guid,
            user_secret: roleIsUser ? null : userSecret,
            user_secret_use: checked,
            locale: 'en'
        }, {
            headers: {
                Authorization: `Bearer ${user.access_token}`
            }
        })

        console.log('d', d)

    }

    useEffect(() => {
        setRoleIsUser(role === '5658d038-ab83-432, width: 2003-9025-d8f38a4b459e')
    }, [role])

    useEffect(() => {
        const getRoles = async () => {
            const { data } = await client.query({
                query: GET_ROLES,
                variables: { locale: 'en', v_tenants_guid: tenants_guid }
            })

            setRoles(data.dal_get_users_list)
        }

        getRoles()
        return () => {
            setUserName('');
            setPassword1('');
            setPassword2('');
            setUserSecret(userSecret);
            setRole('');

        };
    }, [])

    const onChangeCheckbox = (event) => setChecked(event.target.checked)

    const [errorTextField, setErrorTextField] = useState(false)

    useEffect(() => {
        setErrorTextField(false)
    }, [password2])

    return (
        <Box>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
                width: 400,
            }}>
                <div style={{ marginBottom: 32, width: 200 }}>
                    <TextField
                        onChange={(event) => {
                            setUserName(event.target.value)
                        }}
                        value={userName}
                        id="outlined-password-input"
                        label="User name"
                        // type="password"
                        // autoComplete="current-password"
                    />
                </div>
                <div style={{ marginBottom: 32, width: 200 }}>
                    <TextField
                        onChange={(event) => {
                            setPassword1(event.target.value)
                        }}
                        value={password1}
                        id="outlined-password-input"
                        label="Password"
                        type="password"
                        // autoComplete="current-password"
                    />
                </div>
                <div style={{ marginBottom: 32, width: 200 }}>
                    <FormControl error={error} variant="standard">
                        <TextField
                            onChange={(event) => {
                                setPassword2(event.target.value)
                            }}
                            value={password2}
                            id="outlined-password-input"
                            label="Repeat password"
                            type="password"
                            helperText={errorTextField ? 'Password don\'t match' : ''}
                            error={errorTextField}
                            onBlur={() => {
                                if (error) {
                                    setErrorTextField(true)
                                }
                            }}
                            // autoComplete="current-password"
                        />
                    </FormControl>
                </div>
                {/*<div style={{ marginBottom: 32, width: 200 }}>*/}
                {/*    <Typography varinant="h6" style={{ marginRight: 24 }}>User secret</Typography>*/}
                {/*    <TextField*/}
                {/*        onChange={(event) => {*/}
                {/*            setUserSecret(event.target.value)*/}
                {/*        }}*/}
                {/*        value={userSecret}*/}
                {/*        id="outlined-password-input"*/}
                {/*        // label="Password"*/}
                {/*        type="number"*/}
                {/*        // autoComplete="current-password"*/}
                {/*    />*/}
                {/*</div>*/}
                <div style={{ marginBottom: 32, width: 200 }}>
                    <DropDown roles={roles} role={role} setRole={setRole}/>
                </div>
                <div style={{ marginBottom: 32, width: 200, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <FormControlLabel control={<Checkbox
                        checked={checked}
                        onChange={onChangeCheckbox}
                        disabled={roleIsUser}/>} label="User secret use"
                    />
                    <TextField
                        onChange={(event) => {
                            setUserSecret(event.target.value)
                        }}
                        disabled={!checked}
                        value={userSecret}
                        id="outlined-password-input"
                        // label="Password"
                        type="number"
                        // autoComplete="current-password"
                    />
                </div>
            </div>
        </Box>
    );
};