import React, {useEffect} from 'react';
import {useApolloClient} from "@apollo/react-hooks";
import {useDispatch} from "react-redux";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import {getMaps, getMapTypes} from "../apollo/queries";
import "./layout.css";
import Sidebar from "../../components/Sidebar";

import MappingRoutes from "../../router/mappingRoutes";

const MappingLayout = () => {
    const client = useApolloClient();
    const dispatch = useDispatch();

    const locale = localStorage.getItem('locale');

    const _getMapTypes = async () => {
        try{
            const {data, errors} = await client.query({query: getMapTypes, variables: {locale: locale ? locale : "en"}});
            if(data) dispatch({type: 'SET_MAP_TYPES', data: data.dal_get_maps_types_list});
            if(errors?.length > 0){
                throw new Error(errors[0].message)
            }
        }
        catch (e) {
            console.log(e.message);
        }
    }

    useEffect( () => {
        _getMapTypes();
    }, [])

  return (
      <>
          <Sidebar title={"Dynamic Screen"}>
              <Box component="main" className="mapping-wrapper">
                  <Toolbar />
                  <Container maxWidth="false">
                      <div>
                          <MappingRoutes />
                      </div>
                  </Container>
              </Box>
          </Sidebar>
      </>
  )
}

export default MappingLayout;
