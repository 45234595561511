import React, {useState, useEffect} from 'react';
// import Form from '@mui/material';
import {Grid, Button, Paper, List, ListItem, ListItemIcon, Checkbox, ListItemText} from '@mui/material';
import {Form} from '@patternfly/react-core';
import {useSelector} from "react-redux";

import loader from '../../../assets/loader.gif';

const LinkMapAndRegionGroupForm = ({mapRegionGroupAllocationData, setMapRegionGroupAllocationData, shouldUpdate }) => {
    const [unallocatedGroupsChecked, setUnallocatedChecked] = useState([]);
    const [allocatedGroupsChecked, setAllocatedGroupsChecked] = useState([]);

    const handleToggle = (value, side, sideFunc) => () => {
        const currentIndex = side.findIndex(el => el.regions_groups_guid === value.regions_groups_guid);
        const newChecked = [...side];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        sideFunc(newChecked)
    };

    const handleAllToAllocated = () => {
        setMapRegionGroupAllocationData(prevState => ({...prevState, unsavedAllocated: [...prevState.unsavedAllocated, ...prevState.unsavedUnallocated],  unsavedUnallocated: []}));
        shouldUpdate.current = true
    };

    const handleCheckedToAllocated = () => {
        // справа налево
        const unsavedAllocated = mapRegionGroupAllocationData.unsavedAllocated.filter(el => {
            return !allocatedGroupsChecked.find(checked => el.regions_groups_guid === checked.regions_groups_guid);
        });
        setMapRegionGroupAllocationData(prevState => ({...prevState, unsavedAllocated,  unsavedUnallocated: [...prevState.unsavedUnallocated, ...allocatedGroupsChecked]}));
        shouldUpdate.current = true
        setAllocatedGroupsChecked([]);
    };

    const handleCheckedToUnallocated = () => {
        // слева направо
        const unsavedUnallocated = mapRegionGroupAllocationData.unsavedUnallocated.filter(el => {
            return !unallocatedGroupsChecked.find(checked => el.regions_groups_guid === checked.regions_groups_guid);
        });
        setMapRegionGroupAllocationData(prevState => ({...prevState, unsavedUnallocated,  unsavedAllocated: [...prevState.unsavedAllocated, ...unallocatedGroupsChecked]}));
        shouldUpdate.current = true
        setUnallocatedChecked([]);
    };

    const handleAllToUnallocated = () => {
        setMapRegionGroupAllocationData(prevState => ({...prevState, unsavedUnallocated: [...prevState.unsavedAllocated, ...prevState.unsavedUnallocated],  unsavedAllocated: []}));
        shouldUpdate.current = true
    };


    const customList = (title, items, side, sideFunc) => {
        return (
            <>
                <p style={{textAlign: 'center'}}>{title}</p>
                <Paper sx={{width: 200, height: 230, overflow: 'auto'}}>
                    <List dense component="div" role="list">
                        {items?.length >= 0 ? items.map((value) => {
                            const labelId = `transfer-list-item-${value.regions_groups_guid}-label`;

                            return (
                                <ListItem
                                    key={value.regions_groups_guid}
                                    role="listitem"
                                    button
                                    onClick={handleToggle(value, side, sideFunc)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={side.findIndex(el => el.regions_groups_guid === value.regions_groups_guid) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={labelId} primary={value.regions_groups_name}/>
                                </ListItem>
                            );
                        }) : <div><img src={loader}/></div>}
                    </List>
                </Paper>

            </>
        )
    };

    return (
        <Form style={{width: "100%", padding: "10px 15px"}}>

            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>
                    {customList('Allocated regions', mapRegionGroupAllocationData?.unsavedAllocated, allocatedGroupsChecked, setAllocatedGroupsChecked)}
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllToUnallocated}
                            disabled={mapRegionGroupAllocationData?.unsavedAllocated?.length === 0}
                            aria-label="move all alloc"
                        >
                            ≫
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedToAllocated}
                            disabled={allocatedGroupsChecked.length === 0}
                            aria-label="move selected alloc"
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedToUnallocated}
                            disabled={unallocatedGroupsChecked.length === 0}
                            aria-label="move selected unalloc"
                        >
                            &lt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleAllToAllocated}
                            disabled={mapRegionGroupAllocationData?.unsavedUnallocated?.length === 0}
                            aria-label="move all unalloc"
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    {customList('Unallocated regions', mapRegionGroupAllocationData?.unsavedUnallocated, unallocatedGroupsChecked, setUnallocatedChecked)}
                </Grid>
            </Grid>

        </Form>
    );
}

export default LinkMapAndRegionGroupForm;
