import * as React from "react";

export const ActionButtonContext = React.createContext({
    changeActionButton: () => {},
    actionButton: null,
});

export const ActionButtonContextProvider = ({ children }) => {
    const [actionButton, setActionButton] = React.useState(null);
    const selectedActionButton = React.useMemo(
        () => ({
            changeActionButton: (value) => {
                setActionButton(value);
                
            },
            actionButton,
        }),
        [actionButton]
    );
    


    return(
        <ActionButtonContext.Provider value={selectedActionButton}>
            {children}
        </ActionButtonContext.Provider>
    );
};

export const useActionButton = () => React.useContext(ActionButtonContext);

