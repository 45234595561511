import React, { useState, useEffect, useRef } from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import L from 'leaflet';

const PolygonDialogComponent = ({ dialogVisible, closeDialog, value, onUpdateValue }) => {
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);
    const polygonRef = useRef(null);
    const [polygonCoordinates, setPolygonCoordinates] = useState(null);

    const polygonDialogHeader = () => {
        const onUpdate = () => {
            // Implement your update logic here
            if (polygonCoordinates !== null) {
                const latLngs = polygonRef.current.getLatLngs();
                let formattedPolygon = latLngs.map(ring => {
                    const ringCoordinates = ring.map(({ lng, lat }) => `${lng} ${lat}`);
                    // Close the polygon by adding the first point to the end
                    ringCoordinates.push(`${ring[0].lng} ${ring[0].lat}`);
                    return ringCoordinates.join(', ');
                }).join('), (');
                formattedPolygon = `POLYGON((${formattedPolygon}))`;
                onUpdateValue(formattedPolygon);
            } else {
                onUpdateValue(null);
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Polygon editor</span>
                <ButtonComponent onClick={onUpdate} cssClass="e-primary">Confirm</ButtonComponent>
            </div>
        )
    };

    useEffect(() => {
        if (dialogVisible && mapContainerRef.current) {
            if (!mapRef.current) {
                setTimeout(() => {
                    mapRef.current = L.map(mapContainerRef.current).setView([51.505, -0.09], 13);
                    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                        attribution: '&copy; OpenStreetMap contributors'
                    }).addTo(mapRef.current);

                    mapRef.current.pm.addControls({
                        position: 'topleft',
                        drawMarker: false,
                        dragMode: true,
                        drawPolygon: true, // Enable polygon drawing
                        drawCircleMarker: false,
                        drawPolyline: false,
                        drawText: false,
                        drawCircle: false,
                        cutPolygon: false,
                        drawRectangle: false,
                        editMode: true, // Enable editing of polygons
                        deleteLayer: true,
                        rotateMode: false,
                    });

                    // Handle polygon creation
                    mapRef.current.on('pm:create', (event) => {
                        if (event.shape === 'Polygon') {
                            if (polygonRef.current) {
                                mapRef.current.removeLayer(polygonRef.current);
                            }
                            polygonRef.current = event.layer;
                            setPolygonCoordinates(polygonRef.current.getLatLngs())
                        }
                    });

                    // Handle marker removal
                    mapRef.current.on('pm:remove', (event) => {
                        if (event.layer === polygonRef.current) {
                            setPolygonCoordinates(null);
                        }
                    });

                    if (value) {
                        const matchPolygon = value.match(/POLYGON\(\(([-\d.\s,]+)\)\)/);
                        if (matchPolygon) {
                            const coordinates = matchPolygon[1].split(',').map(coord => {
                                const [lng, lat] = coord.trim().split(' ').map(Number);
                                return L.latLng(lat, lng);
                            });
                            polygonRef.current = L.polygon(coordinates).addTo(mapRef.current);
                            mapRef.current.setView(coordinates[0], 7);
                            setPolygonCoordinates(coordinates);
                        }
                    } else {
                        mapRef.current.setView([0, 0], 1);
                    }
                }, 200);
                return () => {
                    if (mapRef.current) {
                        mapRef.current.off(); // Remove event listeners
                        mapRef.current.remove(); // Remove map instance
                        mapRef.current = null;
                    }
                };
            }
        }
    }, [dialogVisible]);

    return (
        <DialogComponent
            showCloseIcon={true}
            width="500px"
            height="500px"
            visible={dialogVisible}
            close={closeDialog}
            header={polygonDialogHeader}
        >
            <div ref={mapContainerRef} id="map" style={{ height: '100%', width: '100%' }}></div>
        </DialogComponent>
    );
};

export default PolygonDialogComponent;