import { createStore } from 'redux';
import { combineReducers } from 'redux';

import mainReducer from './reducer';
import treeViewReducer from './treeView/treeViewReducer';
import manageViewsReducer from './manageViews/manageViewsReducer';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = createStore(combineReducers({
    mainReducer: mainReducer,
    treeViewReducer: treeViewReducer,
    manageViewsReducer: manageViewsReducer,
}));
const persister = 'Free';

export { store, persister };
