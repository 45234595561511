import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ModalMui from '@mui/material/Modal';

import { ModalContainer } from '../styled';
import { Button } from '../Header/styled';

export const Modal = ({ children, modalIsOpen, setModalIsOpen }) => {

    return (
        <ModalMui
            open={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <ModalContainer>
                {children}
            </ModalContainer>
        </ModalMui>
    );
};
